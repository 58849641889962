<template>
  <div class="ekran w-100 h-100">
    <div class="d-flex justify-content-center align-items-center">
      <div class="icerik">
        <h4 class="font-title mt-2">{{ vaka.title }}</h4>

        <button
            class="btn btn-info btn-block font-text"
            type="button"
            @click="toggleAccordion(0)"
        >
          {{ vaka.bilgiBaslik }}
        </button>
        <section v-show="accordions[0]" class="bilgi font-text">
          <div class="card m-1">
            <div class="card-body text-left font-kucuk">
              <p v-html="vaka.bilgiMetni"></p>
            </div>
          </div>
        </section>

        <button
            class="btn btn-info btn-block font-text"
            type="button"
            @click="toggleAccordion(1)"
        >
          {{ vaka.vakaBaslik }}
        </button>
        <section v-show="accordions[1]" class="vaka font-text">
          <div class="card p-0 m-1">
            <div class="card-body p-0">
              <img :src="vaka.vakaMetni" class="img-fluid" rel="preload"/>
            </div>
          </div>
        </section>

        <button
            class="btn btn-info btn-block font-text"
            type="button"
            @click="toggleAccordion(2)"
        >
          {{ vaka.checklistBaslik }}
        </button>
        <section v-show="accordions[2]" class="vaka font-text">
          <form @submit.prevent="cevapla">
            <div class="check-list bg-white text-left">
              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                  <tr>
                    <th class="p-1" scope="col"></th>
                    <th class="p-1" scope="col"></th>
                    <th class="p-1" scope="col">D</th>
                    <th class="p-1" scope="col">Y</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="soru in vaka.checklist"
                      :key="soru.id"
                      :class="[soru.dogru != true ? yanlisClass : '']"
                  >
                    <td class="p-1">
                      <small>{{ soru.id }}</small>
                    </td>
                    <td class="p-1">
                      <small>{{ soru.text }}</small>
                    </td>
                    <td class="p-1">
                      <div class="form-check form-check-inline">
                        <input
                            :id="soru.id"
                            v-model="cevaplar[soru.id]"
                            :name="soru.id"
                            class="form-check-input"
                            required
                            type="radio"
                            value="D"
                        />
                      </div>
                    </td>
                    <td class="p-1">
                      <div class="form-check form-check-inline">
                        <input
                            :id="soru.id"
                            v-model="cevaplar[soru.id]"
                            :name="soru.id"
                            class="form-check-input"
                            type="radio"
                            value="Y"
                        />
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button
                v-if="!sonucGosterimi"
                class="btn btn-warning btn-block"
                type="submit"
            >
              Değerlendirme Sonuçlarını Gör!
            </button>
          </form>
          <div
              v-if="sonucGosterimi"
              class="w-100 alert rounded mt-1 bg-blue text-white"
          >
            <p>
              Doğru Cevap: {{ dogruCevap }} Kazandığınız Puan: {{ toplamPuan }}
            </p>
            <button
                class="btn btn-info btn-block"
                type="button"
                @click="goBack"
            >
              Bitir
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

const keys = [
  {min: 8, max: 10, puan: 30},
  {min: 5, max: 7, puan: 25},
  {min: 3, max: 4, puan: 20},
  {min: 1, max: 2, puan: 10},
];

export default {
  name: "HareketeGec",
  props: ["vaka"],
  components: {},
  data() {
    return {
      accordions: [],
      cevaplar: [],
      dogruCevap: 0,
      yanlisClass: "",
      sonucGosterimi: false,
      toplamPuan: 0,
    };
  },
  mounted: function () {
    console.log(this.vaka);
  },
  computed: {
    ...mapState(["level", "hareketeGecDb"]),
    ...mapGetters(["section", "simdikiSoru"]),
  },
  methods: {
    ...mapActions([
      "getHareketeGecData",
      "addPuan",
      "setAciklamaGosterildi",
      "setSection",
    ]),
    goBack() {
      this.$emit("goBack");
    },
    toggleAccordion(id) {
      // Akordiyon aciksa kapat
      if (this.accordions[id]) {
        this.accordions = this.accordions.map((p) => (p = false));
      } else {
        this.accordions = this.accordions.map((p) => (p = false));
        this.accordions[id] = true;
      }
    },
    cevapla() {
      this.dogruCevap = 0;
      const sorular = this.vaka.checklist;

      for (let key in this.cevaplar) {
        const cevap = this.cevaplar[key];
        const anahtar = sorular.find((p) => p.id == key).cevap;
        //  console.log({ cevap, anahtar });
        if (cevap === anahtar) {
          sorular.find((p) => p.id == key).dogru = true;
          this.dogruCevap += 1;
        }
      }
      this.yanlisClass = "bg-red text-white";
      const puan = keys.find(
          (p) => this.dogruCevap >= p.min && this.dogruCevap <= p.max
      );

      this.toplamPuan = puan.puan;
      this.addPuan(this.toplamPuan);
      this.sonucGosterimi = true;
      // Cevap verildikten sonra section degistir yoksa girip cikip tekrar yapilabiliyor.
      this.setSection("scoreboard");
    },
  },
};
</script>


<style scoped>
.bilgi,
.vaka {
  font-size: 14px;
  line-height: 1.2;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.icerik {
  width: 100%;
  max-width: 400px;
}

.btn {
  margin-top: 5px;
}
</style>
