<template>
  <div
      class="login-form d-flex justify-content-center align-items-center m-1"
      style="max-width: 28rem"
  >
    <div v-if="view == 'login'" id="login" class="card text-white">
      <div class="card-header w-100">Giriş Yap</div>
      <div class="card-body">
        <form @submit.prevent="submitLoginForm">
          <div class="form-row">
            <div class="form-group col">
              <input
                  v-model="eposta"
                  class="form-control form-control-sm"
                  formControlName="eposta"
                  maxlength="35"
                  placeholder="E-posta adresi"
                  required
                  type="email"
              />
              <small id="emailHelp" class="form-text text-center"
              >Bir hesabınız yoksa yeni bir hesap açmak için Kaydol'a
                tıklayın.</small
              >
            </div>
          </div>
          <button
              class="btn btn-light mb-2"
              type="button"
              @click="changeView('register')"
          >
            Kaydol
          </button>
          <button class="btn btn-warning float-right mb-2" type="submit">
            Giriş
          </button>
        </form>
        <SocialShare hidden/>
      </div>
    </div>
    <div v-if="view == 'register'" id="register" class="card text-white">
      <div class="card-header w-100">Kayıt ol</div>
      <div class="card-body">
        <form @submit.prevent="submitRegisterForm">
          <div class="form-row">
            <div class="form-group col">
              <input
                  v-model="registerModel.fullName"
                  class="form-control form-control-sm"
                  formControlName="adSoyad"
                  maxlength="30"
                  placeholder="Ad - Soyad"
                  required
                  type="text"
              />
            </div>
            <div class="form-group col">
              <input
                  v-model="registerModel.email"
                  class="form-control form-control-sm"
                  formControlName="eposta"
                  maxlength="35"
                  placeholder="E-posta adresi"
                  required
                  type="email"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <input
                  v-model="registerModel.phone"
                  class="form-control form-control-sm"
                  formControlName="telefon"
                  maxlength="15"
                  placeholder="Telefon (İsteğe bağlı)"
                  type="text"
              />
            </div>
            <div class="form-group col">
              <input
                  v-model="registerModel.city"
                  class="form-control form-control-sm"
                  formControlName="sehirUlke"
                  maxlength="20"
                  placeholder="Şehir"
                  required
                  type="text"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <select
                  id="inputState"
                  v-model="registerModel.age"
                  class="form-control form-control-sm"
                  formControlName="yas"
                  required
              >
                <option disabled selected value>Yaş</option>
                <option>18-24</option>
                <option>25-30</option>
                <option>31-40</option>
                <option>41-50</option>
                <option>50+</option>
              </select>
            </div>
            <div class="form-group col">
              <select
                  id="inputState"
                  v-model="registerModel.gender"
                  class="form-control form-control-sm"
                  formControlName="cinsiyet"
                  required
              >
                <option disabled selected value>Cinsiyet Kimliği</option>
                <option value="E">Erkek</option>
                <option value="K">Kadın</option>
                <option value="D">Diğer</option>
                <option value="BI">Berlirtmek istemiyorum</option>
              </select>
            </div>
            <div class="form-group col">
              <select
                  id="inputState"
                  v-model="registerModel.education"
                  class="form-control form-control-sm"
                  formControlName="cinsiyet"
                  required
              >
                <option disabled selected value>Eğitim</option>
                <option value="I">İlkokul</option>
                <option value="L">Lise</option>
                <option value="U">Üniversite</option>
                <option value="LU">Lisans üstü</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-auto">
              <label for="aboneOl"
              >Denge ve Denetleme Ağı bültenine abone olmak ister
                misin?</label
              >
              <select
                  id="aboneOl"
                  v-model="registerModel.subscribe"
                  class="form-control form-control-sm"
                  formControlName="abone"
                  required
              >
                <option disabled selected value>Seçiniz</option>
                <option value="true">Evet</option>
                <option value="false">Hayır</option>
              </select>
            </div>
            <div class="form-group col-auto">
              <label for="bilgimVar"
              >Denge ve Denetleme Ağı hakkında daha önce bilgin var
                mıydı?</label
              >
              <select
                  id="bilgimVar"
                  v-model="registerModel.didYouKnowDda"
                  class="form-control form-control-sm"
                  formControlName="cinsiyet"
                  required
              >
                <option disabled selected value>Seçiniz</option>
                <option value="true">Evet</option>
                <option value="false">Hayır</option>
              </select>
            </div>
          </div>
          <p style="font-size: 12px">
            Kayıt olarak, Denge Denetleme Ağı
            <span
                class="badge badge-warning"
                style="cursor: pointer"
                @click="changeView('kvkk')"
            >KVKK Şartlarını</span
            >
            kabul etmiş olursunuz.
          </p>
          <button
              class="btn btn-light mb-2"
              type="button"
              @click="changeView('login')"
          >
            Giriş yap
          </button>
          <button class="btn btn-warning mb-2 float-right" type="submit">
            Kaydol
          </button>
        </form>
      </div>
    </div>
    <Kvkk v-if="view == 'kvkk'" @close="changeView('register')"></Kvkk>
    <modal v-show="modalGoster" @kapat="modalKapat">
      <h5 v-if="modalTitle" class="p-1 text-center bg-danger rounded">
        {{ modalTitle }}
      </h5>
      <div class="mb-1 font-text p-2" v-html="modalText"></div>
    </modal>
    <Spinner v-if="spinner"></Spinner>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {mapActions, mapMutations, mapState} from "vuex";
import Kvkk from "./Kvkk";
import Modal from "@/components/modal/Modal";
import Spinner from "@/components/Spinner";
import SocialShare from "@/components/SocialShare";
import logger from "@/services/log.service";

export default {
  name: "LoginForm",
  components: {Kvkk, Modal, Spinner, SocialShare},
  inject: ["notyf"],
  data: function () {
    return {
      view: "login",
      eposta: "",
      registerModel: {
        fullName: "",
        email: "",
        phone: "",
        city: "",
        age: "",
        gender: "",
        education: "",
        subscribe: "",
        didYouKnowDda: "",
      },
      modalGoster: false,
      modalTitle: null,
      modalText: null,
      spinner: false,
      kaldigimYerView: null,
      girisMuzik: new Audio(require("@/assets/audio/giris.mp3")),
    };
  },
  mounted: function () {
    this.setFooterBackground(false);
  },
  computed: {
    ...mapState(["user", "section", "userData"]),
  },
  methods: {
    ...mapActions([
      "login",
      "register",
      "changeRoute",
      "setFooterBackground",
      "setSection",
    ]),
    ...mapMutations(["loginSuccess"]),
    submitLoginForm() {
      this.spinner = true;
      this.login(this.eposta)
          .then((response) => {
            this.spinner = false;
            this.loginSuccess(response.data);
            this.girisMuzik.play();

            let kaldiginYer = "Ön Test";
            let view = "on-test";

            // Ön teste git
            if (this.section == "ontest" && !this.userData?.OnTestTamamlandi) {
              kaldiginYer = "Ön Test";
              view = "on-test";
              this.changeRoute(view);
              // this.showModalAndGoToView(kaldiginYer, view);
              return;
            }

            if (this.section == "ontest" && this.userData?.OnTestTamamlandi) {
              view = "intro";
              this.setSection("ogren");
              this.changeRoute(view);
              return;
            }

            if (this.section == "sontest" && !this.userData?.SonTestTamamlandi) {
              kaldiginYer = "Son Test";
              view = "on-test";
              this.showModalAndGoToView(kaldiginYer, view);
              return;
            }

            if (this.section == "sontest" && this.userData?.SonTestTamamlandi) {
              kaldiginYer = "Puan Tablosu";
              view = "score-board";
              this.showModalAndGoToView(kaldiginYer, view);
              return;
            }

            // Öğrene git
            if (this.section === "ogren") {
              kaldiginYer = "Öğren Etabı";
              view = "ogren-main";
              this.showModalAndGoToView(kaldiginYer, view);
              return;
            }

            // Deneteleye git
            if (this.section === "denetle") {
              kaldiginYer = "Denetle Etabı";
              view = "ogren-main";
              this.showModalAndGoToView(kaldiginYer, view);
              return;
            }

            // Harekete Geç'e git
            if (this.section === "harekete-gec") {
              kaldiginYer = "Harekete Geç Etabı";
              view = "harekete-gec";
              this.showModalAndGoToView(kaldiginYer, view);
              return;
            }

            // Scoreboard'a git
            if (this.section === "scoreboard") {
              kaldiginYer = "Puan Tablosu";
              view = "score-board";
              this.showModalAndGoToView(kaldiginYer, view);
              return;
            }

            if (this.section === "sertifika") {
              kaldiginYer = "Sertifika";
              view = "sertifika";
              this.showModalAndGoToView(kaldiginYer, view);
              return;
            }

            this.changeRoute("intro")
          })
          .catch((error) => {
            console.dir(error);
            if (error.response?.status === 404) {
              this.modalTitle = "Kullanıcı Kayıtlı Değil";
              this.modalText = "Şimdi kayıt formunu doldurup kaydolabilirsinz.";
            } else {
              this.modalTitle = "Bir hata oluştur. Tekrar dene!";
              this.modalText = error.response?.data.message;
              logger.log(error);
            }
            this.modalGoster = true;
            this.spinner = false;
          });
    },
    submitRegisterForm() {
      this.spinner = true;

      this.registerModel.subscribe = Boolean(this.registerModel.subscribe);
      this.registerModel.didYouKnowDda = Boolean(
          this.registerModel.didYouKnowDda
      );

      this.register(this.registerModel)
          .then((response) => {
            this.spinner = false;
            console.log("Basarili");
            console.dir(response);
            this.modalTitle = "Kayıt Başarılı";
            this.modalText = `${response.data.fullName}, şimdi oyuna giriş yapabilirsin.`;
            this.modalGoster = true;
            this.eposta = response.data.email;
            this.view = "login";
          })
          .catch((error) => {
            this.spinner = false;
            console.log("hatali");
            console.dir(error);
            this.modalTitle = "Hata";
            this.modalText = "Kullanıcı kaydı başarısız. Lütfen tekrar deneyin.";
            this.modalGoster = true;
            logger.log(error);
          });
    },
    changeView(view) {
      this.view = view;
      gsap.from(".card", {
        scale: 0,
        opacity: 0,
        onComplete: () => {
        },
      });
    },
    showModalAndGoToView(kaldiginYer, view) {


      this.modalTitle = "Giriş yapıldı.";
      this.modalText = ` Denge ve Denetleme Oyunu'nu son oynayışında kaldığın yer: ${kaldiginYer}. Şimdi oyuna kaldığın yerden devam edeceksin.`;
      this.modalGoster = true;
      this.kaldigimYerView = view;
      //   this.setSection("ogren");

      console.log("KALDIGINIZ YER");
      console.log(this.section);
      console.log(this.user);
    },
    modalKapat() {
      this.modalGoster = false;
      if (this.kaldigimYerView) this.changeRoute(this.kaldigimYerView);
    },
  },
};
</script>


<style scoped>
#register,
#login {
  background-color: #2f54abde;
  border-top: 3px dashed #f3efd4;
  border-bottom: 3px dashed #f3efd4;
}

.card-header {
  padding: 0.25rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px dashed rgb(255, 255, 255);
}

.card-body {
  padding: 0.55rem;
}
</style>
