import {api} from '../services/api.service';

import kozlar from "../data/kozlar";
import denetleDB from "../data/denetle.json";
import ogrenDB from "../data/ogren.json";
import hareketeGecDB from "../data/harekete-gec.json"

const ogrenSoruSayisi = 9;
const denetleSoruSayisi = 6;


export const sorulariGetir = (context) => {
    let ogrenSorular = context.state.ogrenSorular;
    let denetleSorular = context.state.denetleSorular;

    ogrenSorular = ogrenSorular ? ogrenSorular : getSorular(ogrenDB, false, context.state.session.level);
    denetleSorular = denetleSorular ? denetleSorular : getSorular(denetleDB, true, context.state.session.level);

    context.state.ogrenSorular = ogrenSorular;
    context.state.denetleSorular = denetleSorular;
    context.state.session.ogrenSorular = ogrenSorular;
    context.state.session.denetleSorular = denetleSorular;

    saveSession(context, 'sorulariGetir')
}

export const setFooterBackground = (context, value) => {
    context.state.showFooterBackground = value;
}

export const addPuan = (context, puan) => {
    context.commit('addPuan', puan)
    saveSession(context, 'addPuan')
}

export const sonrakiSoruyaGec = (context) => {
    if (context.state.currentSoruNo != context.getters.soruSayisi) {
        context.state.currentSoruNo++;
        context.state.session.currentSoruNo++;
    } else {
        context.commit("setCurrentSoru", 1);

        if (context.state.section === "denetle") {
            context.dispatch('setAciklamaGosterildi', false)
            context.commit('setSection', "harekete-gec");
        } else {
            context.dispatch('setAciklamaGosterildi', false)
            context.commit('setSection', "denetle");
        }
    }
    saveSession(context, 'sonrakiSoruyaGec')
}

export const setCurrentSoruNo = (context, id) => {
    context.commit('setCurrentSoru', id);
    saveSession(context, `setCurrentSoruNo:${id}`);
}


// Koz ekle
export const addKozToUser = (context, yeniKoz) => {
    const koz = context.state.kozlar.filter(item => item.id === yeniKoz.id)[0];
    if (!koz) {
        context.commit('pushKozToKozlar', yeniKoz);
    } else {
        context.commit('incrementKozQuantity', koz);
    }

    saveSession(context, 'addKozToUser');

}
export const soruDegistir = (context) => {

    let database = ogrenDB;
    if (context.state.section == "denetle") {
        database = denetleDB;
    }

    let currentSoru = context.getters.simdikiSoru;
    let simdikiKoz = currentSoru.koz;

    let random = randomNumber(1, database.length);
    let yeniSoru = JSON.parse(JSON.stringify(database.filter(p => p.id === random)[0]));

    currentSoru.soru = yeniSoru.soru;
    currentSoru.cevaplar = yeniSoru.cevaplar;
    // currentSoru.koz = yeniSoru.koz; 
    currentSoru.koz = simdikiKoz;

    console.table(context.state.ogrenSorular);

    // eldeki kozlari azalt
    context.commit('kozCikar', 3)

    saveSession(context, 'soruDegistir')
}
export const yuzdeElliKoz = (context) => {

    let currentSoru = context.getters.simdikiSoru;
    let cevaplar = currentSoru.cevaplar;


    let yanliscevaplar = cevaplar.filter(p => !p.dogruCevap)
    let dogruCevaplar = cevaplar.filter(p => p.dogruCevap)

    let r1 = randomNumber(0, yanliscevaplar.length - 1);
    let r2 = randomNumber(0, yanliscevaplar.length - 1);
    while (r1 === r2) {
        r2 = randomNumber(0, yanliscevaplar.length - 1);
    }
    yanliscevaplar[r1].hide = true;
    yanliscevaplar[r2].hide = true;

    let yeniCevaplar = dogruCevaplar.concat(yanliscevaplar)

    currentSoru.cevaplar = yeniCevaplar.sort((a, b) => (a.id > b.id) ? 1 : -1);
    context.commit('kozCikar', 2)

    saveSession(context, 'yuzdeElliKoz')

}
export const kozKullan = (context, kozId) => {
    if (kozId === 1) {
        context.commit('passGec');
    } else if (kozId === 4) {
        context.commit('ekBilgiGoster');
    }
    context.commit('kozCikar', kozId)

    saveSession(context, 'kozKullan')

}
export const resetKozStatus = (context) => {
    context.commit('resetKozStatus');
    saveSession(context, 'resetKozStatus')
}
export const updateKozKullanabilir = (context, kozKullanabilir) => {
    context.commit('updateKozKullanabilir', kozKullanabilir);
    saveSession(context, 'updateKozKullanabilir')
}

export const changeRoute = (context, view) => {
    context.commit('changeRoute', view);
}

export const setAciklamaGosterildi = (context, value) => {
    context.state.aciklamaGosterildiMi = value;
    context.state.session.aciklamaGosterildiMi = value;
    saveSession(context, 'setAciklamaGosterildi');
}

export const getHareketeGecData = (context) => {
    context.commit('getHareketeGecData', hareketeGecDB)
    saveSession(context, 'getHareketeGecData')
}

export const setConnected = ({commit}, payload) => {
    commit('setConnected', payload)
}

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getSorular(db, denetle = false, seviye) {
    var soruSayisi = denetle ? denetleSoruSayisi : ogrenSoruSayisi;

    let sorular = [];
    // Denetle etabinda seviye yok
    db = denetle ? db : db.filter(p => p.seviye == seviye);

    for (let i = 1; i < soruSayisi + 1; i++) {
        let rasgeleSayi = randomNumber(0, db.length - 1)
        let soru = db[rasgeleSayi];

        while (sorular.includes(soru)) {
            rasgeleSayi = randomNumber(0, db.length - 1)
            soru = db[rasgeleSayi];
        }


        // 2. Öğrenme etabı için kozları yerlestir
        if (!denetle) {
            // Sadece tek sorulara koz ekle
            if (i % 2 != 0) {
                let mevcutKozlar = kozlar.filter(p => p.kacTane > 0);
                let rasgeleKozNo = randomNumber(0, mevcutKozlar.length - 1);

                soru.koz = mevcutKozlar[rasgeleKozNo];
                kozlar[rasgeleKozNo].kacTane--;
            }

        }

        soru.orjinalId = soru.id;
        soru.id = i;
        sorular.push(soru);
    }

    return sorular;
}

// User Log in
export const login = (context, email) => {
    return api.login(email);
}

export const register = (context, model) => {
    return api.register(model);
}

export const sendOnTest = (context, model) => {
    return api.sendOnTest(model);
}

export const setLevel = (context, level) => {
    context.commit("setLevel", level);

    saveSession(context, 'setLevel');
}

export const resetGame = (context) => {

    context.state.session = {}
    let userid = context.state.userId ? context.state.userId : 17;
    const model = {
        userId: userid,
        session: null
    }
    api.saveSession(model)
        .then((response) => {
            console.log("Oyun Resetlendi")
            localStorage.clear();
            window.location.href = "/";
            //context.dispatch('changeRoute', 'login-form')
        })
        .catch((error) => {
            console.log("Oyun Resetlenmesi Hata")
            console.dir(error)
        });
}

export const getScore = (context, userId) => {
    // const userId = context.state.userId
    console.log(userId)
    return api.getScore(userId);
}

export const endGame = (context) => {
    // oyun bitiminde section=scoreboard yap
    context.commit('setSection', "scoreboard");
    const model = {
        userId: context.state.userId,
        Level: context.state.level,
        Score: context.state.puan
    }
    console.log("ENDGAME MODEL")
    console.log(model)

    return api.endGame(model);
}

export const newGame = (context, userId) => {
    return api.newGame(userId);
}

export const setSection = (context, section) => {
    context.commit('setSection', section);
    saveSession(context, "setSection")
}

export const setArkaArkayaDogruCevap = (context, value) => {
    context.state.arkaArkayaDogruCevap = Number(value);
}

const saveSession = (context, action) => {

    const session = context.state.session;
    const user = context.state.user;
    const model = {
        userId: context.state.userId,
        session: JSON.stringify(session)
    }

    api.saveSession(model)
        .then((response) => {
            console.log(`action: ${action}`);
        })
        .catch((error) => {
            console.log(`Error in action: ${action}`);
            console.dir(error);
        })
        .finally();
}