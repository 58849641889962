<template>
  <div class="modal" style="display:block">
    <div class="modal-dialog modal-dialog-centered p-3 modal-sm">
      <div class="modal-content p-3 bg-blue text-white">
        <slot></slot>
        <button class="btn btn-sm btn-warning font-title" type="button" @click.prevent="kapat">Tamam</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {},
  mounted: function () {
    // console.log("modal mounted");
  },
  methods: {
    kapat(cevap) {
      this.$emit("kapat");
    }
  }
};
</script>


<style scoped>
.modal-content {
  border-radius: 0;
}

.modal {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-header {
  padding: 0.2rem 0.6rem;
  border-bottom: 0;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  /* border-top: 1px solid #dee2e6; */
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-content {
  position: relative;
  border: 0;
  border-radius: 0.4em;
}

.modal-content:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #f4b513;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}
</style>