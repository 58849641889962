<template>
  <div
      :id="'soru'+soru.id"
      :class="itemClass"
      class="card-component d-flex justify-content-center align-items-center m-1 font-text"
      @mouseout="mouseout"
      @mouseover="mouseover"
      @click.stop="soruGoster(soru.id,$event)"
  >
    <div>
      <span class="badge badge-light rounded-circle p-2">{{ soru.id }}</span>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {mapState} from "vuex";

export default {
  name: "OgrenCard",
  props: {
    soru: Object
  },
  data: function () {
    return {
      itemClass: null
    };
  },
  computed: {
    ...mapState(["currentSoruNo", "ogrenSorular"])
  },
  mounted: function () {
    if (this.currentSoruNo == this.soru.id) {
      this.itemClass = "mavi-top";
    } else if (this.currentSoruNo > this.soru.id) {
      this.itemClass = "sari-top";
    }
  },
  methods: {
    soruGoster: function (item, $event) {
      if (this.currentSoruNo == this.soru.id) {
        gsap.fromTo(
            `#soru${this.soru.id}`,
            {
              rotate: 0,
              scale: 1
            },
            {
              rotate: 360,
              scale: 0,
              opacity: 0,
              onComplete: () => {
                this.$emit("soru-goster", item);
              }
            }
        );
      }
    },
    mouseover: function ($event) {
      if (this.itemClass != "sari-top") {
        gsap.set(`#soru${this.soru.id}`, {transformOrigin: "50% 50%"});
        gsap.fromTo(
            `#soru${this.soru.id}`,
            {rotate: 0},
            {rotate: -360, scale: 1, yoyo: true}
        );
      }
    },
    mouseout: function ($event) {
      if (this.itemClass != "sari-top") {
        gsap.to(`#soru${this.soru.id}`, {rotate: 0, scale: 1});
      }
    }
  }
};
</script>


<style scoped>
.card-component {
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/blob.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 14vh;
}

.mavi-top {
  background-image: url("../../../assets/blob-denetle.svg");
}

.sari-top {
  background-image: url("../../../assets/blob-done.svg");
}

.box {
}

.box:hover {
}
</style>
