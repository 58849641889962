<template>
  <div class="board w-100 h-100 text-center">
    <div class="bg-darkblue">
      <img class="img-fluid" src="@/assets/dd-logo.svg"/>
    </div>
    <div
        v-if="!restartView"
        class="sertifika d-flex flex-column justify-content-center align-items-center w-100 h-100"
    >
      <div id="capture" class="background w-100 d-flex justify-content-center">
        <canvas
            id="canvas"
            ref="canvas"
            height="984"
            hidden
            width="1394"
        ></canvas>
        <div class="text-center">
          <img
              class="img-fluid"
              src="@/assets/oyun-logo.png"
              style="width: 40%"
          />
          <p class="lead text-white text-center font-title">Tebrikler!</p>
          <p class="lead text-white text-center font-title">
            Denge ve Denetleme Oyunu'nu başarıyla bitirerek bu belgeyi almaya
            hak kazandın.
          </p>
          <canvas id="canvas2" ref="canvas2"></canvas>
        </div>
      </div>
      <div class="d-flex justify-content-around align-items-center">
        <button
            id="indir-button"
            class="m-1 btn btn-block btn-warning"
            type="button"
            @click="indir"
        >
          İndir
        </button>
      </div>
    </div>

    <div
        v-if="restartView"
        class="restart d-flex flex-column justify-content-center align-items-center w-100 h-100"
    >
      <div class="w-100">
        <div class="w-100 m-1 p-1 rounded">
          <img
              class="img-fluid"
              src="@/assets/oyun-logo.png"
              style="width: 40%"
          />
          <p class="text-white font-title mt-2">
            Bu oyunu arkadaşlarınla paylaş!
          </p>
          <SocialShare/>
          <p class="text-white font-text m-3 small">
            Oyunu yeniden oynayarak daha fazla bilgi edinip, puanını biraz daha
            yükseltmek ister misin?
          </p>
          <button
              id="indir-button"
              class="m-1 btn btn-warning mt-3"
              type="button"
              @click="yeniOyunBaslat"
          >
            Yeniden Başla
          </button>
        </div>
      </div>
    </div>
    <modal v-show="modalGoster" @kapat="modalGoster = !modalGoster">
      <h5 v-if="modalTitle" class="p-1 text-center bg-danger rounded">
        {{ modalTitle }}
      </h5>
      <div class="mb-1 font-text p-2" v-html="modalText"></div>
    </modal>
    <Spinner v-if="spinner"></Spinner>
  </div>
</template>

<script>
// import { gsap } from "gsap";

import {mapActions, mapState} from "vuex";
import Modal from "@/components/modal/Modal";
import Spinner from "@/components/Spinner";
import logger from "@/services/log.service";
import SocialShare from "@/components/SocialShare";

export default {
  name: "Sertifika",
  components: {Modal, Spinner, SocialShare},
  data: function () {
    return {
      restartView: false,
      user: null,
      spinner: false,
      modalGoster: false,
      modalTitle: null,
      modalText: null,
    };
  },
  computed: {
    ...mapState({
      stateUser: "user",
      session: "session",
    }),
  },
  mounted() {
    this.user = this.stateUser
        ? this.stateUser
        : JSON.parse(localStorage.getItem("user"));

    console.log;
    var canvas = this.$refs.canvas;

    let w = canvas.width;
    let h = canvas.height;

    var ctx = canvas.getContext("2d");

    var img = new Image(1394, 984);
    img.src = "/static/sertifika/sertifika.png";
    img.addEventListener(
        "load",
        () => {
          ctx.drawImage(img, 0, 0, w, h);
          ctx.font = "50px Georgia, serif";
          ctx.textAlign = "center";
          ctx.fillText(`Sayın ${this.user.fullName}`, 720, 150);
          ctx.font = "25px Georgia, sans-serif";
          var ts = new Date();
          let options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          ctx.fillText(ts.toLocaleString("tr-TR", options), 320, 850);
        },
        false
    );

    //Simdi goster
    var canvas2 = this.$refs.canvas2;

    var context = canvas2.getContext("2d");

    //set dimensions
    let w2 = 350;
    let h2 = 247;
    canvas2.width = w2;
    canvas2.height = h2;

    img.addEventListener(
        "load",
        () => {
          context.drawImage(img, 0, 0, w2, h2);
          context.font = "20px Georgia, serif";
          context.textAlign = "center";
          context.fillText(`Sayın ${this.user.fullName}`, 175, 55);
        },
        false
    );
  },
  methods: {
    ...mapActions(["newGame", "changeRoute"]),
    indir() {
      var canvas = this.$refs.canvas;
      var image = this.$refs.image;

      var img = canvas.toDataURL("image/png");
      this.saveAs(img, "file-name.png");
      this.restartView = true;
      //
    },
    paylas() {
    },
    saveAs(uri, filename) {
      var link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },
    yeniOyunBaslat() {
      this.userId = this.stateUserId
          ? this.stateUserId
          : localStorage.getItem("userId");

      this.spinner = true;
      this.newGame(this.userId)
          .then((response) => {
            this.spinner = false;
            window.location.href = "/";
          })
          .catch((error) => {
            console.dir(error);
            this.modalTitle = "Bir hata oluştur. Tekrar dene!";
            this.modalText = error.response?.data.message;
            this.modalGoster = true;
            this.spinner = false;
            logger.log(error);
          });
    },
  },
};
</script>


<style scoped>
.board {
  background-color: #6e6e6ec7;
  max-width: 500px;
  /*   overflow: auto;
  max-height: 95%;  */
}

#canvas {
  border: solid 1px blue;
}

#canvas2 {
}

#image {
  width: 1394px !important;
  height: 984px !important;
}

.background {
}
</style>
