<template>
  <div
      class="ogren-main d-flex justify-content-center align-items-center w-100 h-100"
  >
    <ogren-board v-if="session.level"></ogren-board>
    <game-level v-else @select-level="selectLevel"></game-level>

    <modal v-show="modalGoster" :title="title" @kapat="modalKapat">
      <h5 class="p-1 text-center bg-info rounded">
        {{ title }}
      </h5>
      <div class="mb-1 text-center" v-html="text"></div>
    </modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

import GameLevel from "./GameLevel.vue";
import OgrenBoard from "./OgrenBoard.vue";
import Modal from "@/components/modal/Modal";

export default {
  name: "OgrenMain",
  components: {
    GameLevel,
    OgrenBoard,
    Modal,
  },
  inject: ["notyf"],
  data: function () {
    return {
      color: "red",
      modalGoster: false,
      title: "",
      text: "",
      sonrakiModal: true,
      oyunBasladi: false,
      levelGoster: false,
    };
  },
  mounted: function () {
    if (this.section === "ogren") {
      this.title = "Öğren Etabı";
      this.text =
          "9 tane soru cevaplayacaksın. İlk seferde doğru yanıtı bulursan hem puanı hem de kozu kazanacaksın.";
    } else if (this.section === "denetle") {
      this.title = "Denetle Etabı";
      this.text =
          "6 tane soru cevaplayacaksın. İlk seferde doğru yanıtı bulursan puanı kazanacaksın. Öğren etabında kazandığın kozları bu etapta kullanabilirsin.";
    }

    if (this.section != "ogren" && !this.aciklamaGosterildiMi) {
      this.modalGoster = true;
    }
  },
  computed: {
    ...mapState(["puan", "session", "aciklamaGosterildiMi"]),
    ...mapGetters(["section", "simdikiSoru"]),
  },
  methods: {
    ...mapActions(["setAciklamaGosterildi", "sorulariGetir", "setLevel"]),
    selectLevel: function (level) {
      this.setLevel(level);

      // Sorularin secimini yap
      this.sorulariGetir();

      // seviye secili degilse etap bilgisi goster
      if (!this.aciklamaGosterildiMi) {
        this.modalGoster = true;
      }
    },
    modalKapat: function () {
      if (this.sonrakiModal) {
        this.modalGoster = false;
        if (this.section === "ogren") {
          this.title = "Kozlar";
          this.text =
              "<span class='badge badge-warning'><i class=\"fas fa-forward\"></i> Pas</span> Soru açıldıktan sonra bu kozu kullanmak istersen, sorunun doğru cevabını görürsün, puanı kazanır ve bir sonraki soruya geçersin. <br/>";
          this.text +=
              "<span class='badge badge-success'><i class=\"fas fa-percent\"></i> %50</span> Bu kozu seçtiğinde, yanlış cevap şıklarından 2 tanesi rastlantısal olarak silinir. <br/>";
          this.text +=
              "<span class='badge badge-dark'><i class=\"fas fa-recycle\"></i>  Değiştir</span> Soru açıldıktan sonra bu kozu seçersen, yeni bir soru gelir. <br/>";
          this.modalGoster = true;
          this.sonrakiModal = false;
        } else {
          this.title = "Denetle Etabı";
          this.text =
              "Öğren etabında kazandığın kozları bu etapta kullanabilirsin.";
          this.modalGoster = true;
          this.sonrakiModal = false;
        }
      } else {
        this.modalGoster = false;
        this.setAciklamaGosterildi(true);
      }
    },
  },
};
</script>


<style scoped>
.ogren-main {
  /*   max-height: 90%; */
}
</style>
