<template>
  <div
      class="game-level d-flex justify-content-center align-items-center w-100"
  >
    <div class="p-1 text-center">
      <p class="h5 p-2 bg-light rounded">Zorluk seviyesini seçiniz</p>
      <div class="d-flex flex-column">
        <div class="level">
          <div
              id="kolay"
              class="card bg-blue text-white mt-2 font-text"
              @click="selectLevel('kolay')"
          >
            Kolay
            <span class="bg-white text-dark rounded p-1"
            >Ben bu işlerde çok yeniyim, oyunla öğreneceğim.</span
            >
          </div>
        </div>
        <div class="level">
          <div
              id="orta"
              class="card bg-darkblue font-text text-white mt-2"
              @click="selectLevel('orta')"
          >
            Orta
            <span class="bg-white text-dark rounded p-1"
            >Ben Denge ve Denetleme Ağı hakkında biraz bilgi sahibiyim, ama
              bir görelim.</span
            >
          </div>
        </div>
        <div class="level">
          <div
              id="zor"
              class="card bg-red font-text text-white mt-2"
              @click="selectLevel('zor')"
          >
            Zor
            <span class="bg-white text-dark rounded p-1"
            >Ben Denge ve Denetleme Ağı'nın tüm konularına hakimim!</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {mapActions} from "vuex";

export default {
  name: "Level",
  data: function () {
    return {
      color: "red",
    };
  },
  mounted() {
    this.setFooterBackground(false);
  },
  methods: {
    ...mapActions(["setFooterBackground", "setSection"]),
    selectLevel: function (level) {
      gsap.fromTo(
          `#${level}`,
          {rotate: 0},
          {
            rotate: 360,
            scale: 0,
            duration: 0.5,
            onComplete: () => {
              this.$emit("select-level", level);
            },
          }
      );
    },
  },
};
</script>


<style scoped>
.game-level {
  max-width: 70%;
}

.level {
  max-height: 90%;
  max-width: 350px;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.35s cubic-bezier(0.1, 0, 0, 1);
}

.level span {
  font-size: 75%;
}

.level:hover {
  -webkit-transform: rotateZ(30deg);
  -ms-transform: rotateZ(30deg);
  transform: rotateZ(30deg);
  transform: scale(1.1);
}

.h5 {
  border: 3px solid #1c2d4f;
}
</style>
