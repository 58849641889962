import Vue from 'vue'
import store from './store'
import {Notyf} from 'notyf';
import 'notyf/notyf.min.css';

import App from './App.vue'


import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

Vue.config.productionTip = false


Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.toUpperCase();
})


new Vue({
    provide: () => {
        return {
            notyf: new Notyf({
                duration: 2000,
                position: {
                    x: 'center',
                    y: 'center',
                },
            })
        }
    },
    store,
    render: h => h(App),
    created() {

        window.addEventListener('offline', () => {
            store.dispatch('setConnected', false)
        })
        window.addEventListener('online', () => {
            store.dispatch('setConnected', true)
        })
    }
}).$mount('#app')