<template>
  <div
      class="main-menu d-flex flex-column flex-sm-row flex-md-row justify-content-center align-items-center w-100 h-100"
  >
    <div
        id="menu1"
        :class="{ bw: section != 'ogren' }"
        class="menu text-center"
        @click="open('ogren-main', $event)"
    >
      <img
          class="img-fluid"
          src="../assets/Ogren.svg"
          @mouseout="mouseout"
          @mouseover="mouseover"
      />
    </div>

    <div
        id="menu2"
        :class="{ bw: section != 'denetle' }"
        class="menu text-center"
        @click="open('denetle', $event)"
    >
      <img
          class="img-fluid"
          src="../assets/Denetle.svg"
          @mouseout="mouseout"
          @mouseover="mouseover"
      />
    </div>

    <div
        id="menu3"
        :class="{ bw: section != 'harekete-gec' }"
        class="menu text-center"
        @click="open('harekete-gec', $event)"
    >
      <img
          class="img-fluid"
          src="../assets/Hareketegec.svg"
          @mouseout="mouseout"
          @mouseover="mouseover"
      />
    </div>
    <modal v-show="modalGoster" @kapat="modalKapat">
      <h5 v-if="title" class="p-1 text-center bg-danger rounded">
        {{ title }}
      </h5>
      <div class="mb-1 font-text p-2" v-html="modalText"></div>
    </modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {gsap, Power4} from "gsap";

import Modal from "@/components/modal/Modal";

export default {
  name: "MainMenu",
  components: {
    Modal,
  },
  inject: ["notyf"],
  data: function () {
    return {
      modalGoster: false,
      title: null,
      modalText: null,
      timeline: gsap.timeline(),
    };
  },
  computed: {
    ...mapState(["level", "puan", "session"]),
    ...mapGetters(["section", "simdikiSoru"]),
  },
  mounted: function () {
    // Butun bolumler bittiyse dogrudan score board ac
    if (this.section == "scoreboard") {
      this.changeRoute("score-board");
    }
    this.setFooterBackground(false);
  },
  methods: {
    ...mapActions(["init", "changeRoute", "setFooterBackground"]),
    mouseover: function ($event) {
      gsap.set($event.target, {transformOrigin: "50% 50%"});
      gsap.fromTo(
          $event.target,
          {rotate: 0},
          {rotate: 360, scale: 1.3, yoyo: true}
      );
    },
    mouseout: function ($event) {
      gsap.to($event.target, {rotate: 0, scale: 1});
    },
    open: function (page, $event) {
      gsap.fromTo(
          $event.target,
          {rotate: 0},
          {
            rotate: 360,
            scale: 1.5,
            duration: 0.5,
            onComplete: () => {
              /*       if (page == "harekete-gec")
                  this.changeRoute("harekete-gec"); */

              // sontest ise dogrudan son teste git
              if (this.section === "sontest") {
                this.changeRoute("on-test");
              }

              if (page == "ogren-main" && this.section === "ogren") {
                this.changeRoute("ogren-main");
                return;
              }

              if (page == "denetle" && this.section === "denetle") {
                this.changeRoute("ogren-main");
                return;
              }

              if (page == "harekete-gec" && this.section === "harekete-gec") {
                this.changeRoute("harekete-gec");
              } else {
                if (this.section === "ogren") {
                  this.modalText =
                      'Çok hızlı gittin! Önce "Öğren" etabını tamamla!';
                } else if (this.section === "denetle") {
                  this.modalText = '"Denetle" etabını tamamla!';
                } else if (this.section === "harekete-gec") {
                  this.title = "Harekete Geç";
                  this.modalText = "Harekete Geç etabındasın. Son etap!";
                }
                this.modalGoster = true;
              }
            },
          }
      );
      // Make fullscreen
      this.goFullScreen();
    },
    modalKapat: function () {
      this.modalGoster = false;
    },
    goFullScreen() {
      let docElm = document.querySelector("html");
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    },
    enter(el, done) {
      const tl = gsap.timeline({
        onComplete: done,
      });

      tl.set(el, {
        autoAlpha: 0,
        scale: 2,
        transformOrigin: "50% 50%",
      });

      tl.to(el, 1, {
        autoAlpha: 1,
        scale: 1,
        ease: Power4.easeOut,
      });
    },
    leave(el, done) {
      gsap.to(el, 1, {
        scale: 0,
        ease: Power4.easeOut,
        onComplete: done,
      });
    },
  },
};
</script>


<style scoped>
.main-menu {
  width: 100vw;
}

.menu {
  cursor: pointer;
  width: 100%;
}

.bw {
  /*  filter: grayscale(100%); */
}

@media (max-width: 576px) {
  .img-fluid {
    max-height: 180px;
  }
}
</style>
