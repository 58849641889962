export default {
    init: (state) => {

    },
    setCurrentSoru: (state, id) => {
        state.currentSoruNo = id;
        state.session.currentSoruNo = id;
    },
    addPuan: (state, puan) => {
        state.puan = Number(state.puan) + puan;
        state.session.puan = state.puan;
    },
    setSection: (state, section) => {
        state.section = section;
        state.session.section = state.section;
    },
    setLevel: (state, level) => {
        state.level = level;
        state.session.level = state.level;
    },
    // Kozlar
    pushKozToKozlar: (state, koz) => {
        koz.kacTane = 1;
        state.kozlar.push(koz);
        state.session.kozlar = state.kozlar;
    },
    incrementKozQuantity: (state, koz) => {
        koz.kacTane++;
        state.session.kozlar = state.kozlar;
    },
    kozCikar: (state, kozId) => {
        const i = state.kozlar.filter(k => k.id === kozId)[0];
        i.kacTane--;
        state.session.kozlar = state.kozlar;
    },
    passGec: (state) => {
        state.kozKullan = "PAS";
    },
    ekBilgiGoster: (state) => {
        state.kozKullan = "EKBILGI";
    },
    resetKozStatus: (state) => {
        state.kozKullan = null;
    },
    updateKozKullanabilir: (state, kozKullanabilir) => {
        state.kozKullanabilir = kozKullanabilir;
    },
    changeRoute: (state, view) => {
        state.view = view;
    },
    getHareketeGecData: (state, database) => {
        state.hareketeGecDb = database;
    },
    // Login mutations
    loginSuccess(state, data) {

        console.dir("loginSuccess: Login Data")
        console.dir(data)

        const session = JSON.parse(data.user.session);

        // set token data in storage
        localStorage.setItem('token', JSON.stringify(data.token));
        localStorage.setItem('userId', data.user.userId);
        localStorage.setItem('user', JSON.stringify(data.user));

        state.status = {loggedIn: true};
        state.user = data.user;
        state.userId = data.user.userId;
        state.userData = data.user.userData ? JSON.parse(data.user.userData) : null; // OnTest tamamlandi vs bilgileri tutmak icin

        // Session degeri ilk login'de yok
        if (session) {
            state.session = session;
            if (session.currentSoruNo) state.currentSoruNo = session.currentSoruNo;
            if (session.kozlar) state.kozlar = session.kozlar;
            if (session.puan) state.puan = session.puan;
            if (session.section) state.section = session.section;
            if (session.kozKullan) state.kozKullan = session.kozKullan;
            if (session.kozKullanabilir) state.kozKullanabilir = session.kozKullanabilir;
            if (session.level) state.level = session.level;
            if (session.aciklamaGosterildiMi) state.aciklamaGosterildiMi = session.aciklamaGosterildiMi;
            if (session.ogrenSorular) state.ogrenSorular = session.ogrenSorular;
            if (session.denetleSorular) state.denetleSorular = session.denetleSorular;
            if (session.hareketeGecDb) state.hareketeGecDb = session.hareketeGecDb;
        }


    },
    setSession(state, session) {
        state.session = session;
    },
    setConnected(state, payload) {
        state.connected = payload;
    },
}