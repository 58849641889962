<template>
  <div id="app">
    <div class="d-flex justify-content-center align-items-center app">
      <component :is="view" @changePage="changeRoute"></component>
    </div>
    <div
        id="footer"
        class="d-flex justify-content-center align-items-center font-text"
    >
      <div
          :class="[{ 'bg-darkblue': showFooterBackground }]"
          class="m-0 p-0 w-100 text-center kutucuk h-100 d-flex justify-content-center align-items-center"
      >
        <Spinner v-if="!connected"></Spinner>
        <p class="ogrenme">
          powered by
          <a
              class="text-white"
              href="https://ogrenmetasarimlari.com/"
              target="_blank"
          >öğrenme tasarımları</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "./components/MainMenu.vue";
import SplashScreen from "./components/SplashScreen.vue";
import Information from "./components/Information.vue";
import Intro from "./components/Intro";
import OnTest from "./components/OnTest.vue";
import LoginForm from "./components/login/LoginForm.vue";
import OgrenMain from "./components/boards/ogren/OgrenMain.vue";
import HareketeGec from "./components/boards/harekete-gec/HareketeGec.vue";
import Sertifika from "./components/Sertifika";
import ScoreBoard from "./components/ScoreBoard";
import Spinner from "./components/Spinner";
import Admin from "./components/Admin";
import {mapActions, mapState} from "vuex";

export default {
  name: "App",
  components: {
    OnTest,
    MainMenu,
    OgrenMain,
    LoginForm,
    SplashScreen,
    HareketeGec,
    Information,
    Intro,
    Sertifika,
    ScoreBoard,
    Spinner,
    Admin,
  },
  data: function () {
    return {
      dev: false,
    };
  },
  computed: {
    ...mapState({
      view: "view",
      user: "user",
      session: "session",
      puan: "puan",
      connected: "connected",
      showFooterBackground: "showFooterBackground",
    }),
  },
  created: function () {
    // veri tabanindan butun verileri getir // OYUNUN BASLANGICI BURASI OK
  },
  mounted: function () {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    if (page) {
      this.changeRoute(page);
    } else if (this.dev) {
      console.log("development");
      this.changeRoute("splash-screen");
    } else {
      this.changeRoute("splash-screen");
      setTimeout(() => {
        this.changeRoute("login-form");
      }, 3000);
    }
  },
  methods: {
    ...mapActions(["changeRoute"]),
  },
};
</script>

<style>
@import "./assets/styles/bootstrap.min.css";


#app {
  height: 100vh;
  overflow-y: auto;
}

.app {
  height: 98%;
  overflow: hidden;
}

#footer {
  height: 2%;
}

#footer .kutucuk {
  max-width: 500px;
}

#footer .ogrenme {
  font-size: 8px;
  color: rgb(235, 235, 235);
  margin: 0;
}

body {
  background-image: url(assets/bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-red {
  background-color: #ee2d21;
}

.bg-yellow {
  background-color: #f4b513;
}

.bg-white {
  background-color: #f3efd4;
}

.bg-blue {
  background-color: #2f54ab;
}

.bg-darkblue {
  background-color: #1c2d4f;
}

.text-white {
  color: whitesmoke;
}

.text-yellow {
  color: #f4b513;
}

.text-darkblue {
  color: #1c2d4f;
}

body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

.btn,
a.btn:active {
  transform: scale(0.98);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.3);
}

.font-title {
  font-family: "Paytone One", sans-serif;
}

.font-text {
  font-family: "Raleway", sans-serif;
}

.font-kucuk {
  font-size: 12px !important;
}
</style>
