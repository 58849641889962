<template>
  <div class="admin w-100 h-100">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div
          v-if="!spinner"
          class="w-100 bg-blue text-white rounded"
          style="max-width: 450px"
      >
        <div class="input-group p-3">
          <input
              v-model="password"
              class="form-control input-lg"
              placeholder="Password"
              type="password"
          />
          <button class="btn btn-danger" type="button" @click="get">
            İndir
          </button>
        </div>
      </div>
      <div v-if="spinner" class="spinner-grow text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {api} from "../services/api.service";

export default {
  name: "Admin",
  components: {},
  data: function () {
    return {password: null, spinner: false};
  },
  computed: {
    ...mapState(["level", "puan", "session"]),
    ...mapGetters(["section", "simdikiSoru"]),
  },
  mounted: function () {
  },
  methods: {
    ...mapActions(["init", "changeRoute", "setFooterBackground"]),
    get: function () {
      this.spinner = true;
      api
          .getOyunIstatistikleri(this.password)
          .then((data) => data.text())
          .then((content) => {
            this.spinner = false;
            var csvContent = content;
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute(
                "href",
                "data:text/csv;charset=utf-8,\uFEFF" + encodedUri
            );
            link.setAttribute("download", "report.csv");
            link.click();
          })
          // .then((rs) => new Response(rs.body))
          // .then((response) => response.blob())
          // .then((blob) => URL.createObjectURL(blob))
          // .then((url) => {
          //   const link = document.createElement("a");
          //   link.href = url;
          //   link.setAttribute("download", "file.xlsx");
          //   document.body.appendChild(link);
          //   link.click();
          // })
          .catch(console.error);
    },
  },
};
</script>


<style scoped>
</style>
