import axios from 'axios'

export function getToken() {
    let token = JSON.parse(localStorage.getItem('token'));
    return token;
}

let apiUrl = "https://www.dengevedenetlemeoyunu.org";

if (process.env.NODE_ENV === "development") apiUrl = "https://localhost:5001"


export const api = {
    login,
    logout,
    getAll,
    register,
    sendOnTest,
    getSession,
    saveSession,
    getScore,
    endGame,
    newGame,
    getOyunIstatistikleri
};

function register(model) {
    return axios.post(`${apiUrl}/login/register`, model, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}


function login(email) {
    return axios.post(`${apiUrl}/login`, {email: email}, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}


function getSession(id) {
    return axios.post(`${apiUrl}/session/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

function saveSession(model) {
    let token = getToken();
    return axios.post(`${apiUrl}/session`, model, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}

function getScore(userId) {
    return axios.get(`${apiUrl}/game/${userId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

function endGame(model) {
    let token = getToken();
    return axios.post(`${apiUrl}/game/end-game`, model, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}

function newGame(userId) {
    let token = getToken();
    let model = {userId: Number(userId)}
    return axios.post(`${apiUrl}/game/new-game`, model, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}

function sendOnTest(model) {
    let token = getToken();
    return axios.post(`${apiUrl}/game/ontest-sontest?isOnTest=${model.isOnTest}`, model, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
    };

    return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
}


function getOyunIstatistikleri(sifre) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return fetch(`${apiUrl}/admin/${sifre}`, requestOptions);
}

function handleResponse(response) {
    return response.text()
        .then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                return Promise.reject(response);
            }
            return data;
        });
}