<template>
  <div
      class="splash-screen d-flex justify-content-center align-items-center h-100 text-white text-center"
  >
    <div>
      <img class="img-fluid" src="@/assets/oyun-logo.png" style="width:70%"/>

      <p class="font-text p-4 mt-1">Bu oyunda Denge ve Denetleme Ağı'nı eğlenerek öğreneceksin.</p>
      <img class="img-fluid" src="@/assets/dd-logo.svg" style="width:60%" @click="resetGame"/>
      <br/>
      <img class="img-fluid" src="@/assets/sida-logo.png" style="width:20%"/>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SplashScreen",
  data: function () {
    return {};
  },
  beforeDestroy: function () {
  },
  methods: {
    ...mapActions(["resetGame"]),
  },
};
</script>


<style scoped>
.splash-screen {
  background-color: #6e6e6ec7;
  max-width: 500px;
  /*   overflow: auto;
  max-height: 95%;  */
}
</style>
