<template>
  <div id="spinner" class="d-flex justify-content-center align-items-center">
    <div class="spinner-grow text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  data() {
    return {};
  },
  methods: {},
};
</script>


<style scoped>
#spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-color: rgba(240, 248, 255, 0.562);
}
</style>
