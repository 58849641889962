<template>
  <div class="on-test d-flex flex w-100 h-100 container bg-white">
    <div class="w-100 h-100 overflow-auto">
      <h5 class="text-center p-2 bg-yellow text-white">{{ title }}</h5>
      <p class="small text-center">
        Lütfen deneyimlere dayanarak aşağıdaki soruları cevaplayınız. Sizin
        vereceğiniz cevaplar oyunu geliştirmemize ve ihtiyaçlarınızı
        karşılamamıza yardımcı olacaktır.
      </p>
      <p class="small mt-2">1: Kesinlikle katılmıyorum</p>
      <p class="small">2: Katılmıyorum</p>
      <p class="small">3: Kararsızım</p>
      <p class="small">4: Katılıyorum</p>
      <p class="small">5: Kesinlikle Katılıyorum</p>

      <form @submit.prevent="submitTest">
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
            <tr>
              <!--      <th class="p-1" scope="col"></th> -->
              <th class="p-1" scope="col"></th>
              <th class="p-1" scope="col">1</th>
              <th class="p-1" scope="col">2</th>
              <th class="p-1" scope="col">3</th>
              <th class="p-1" scope="col">4</th>
              <th class="p-1" scope="col">5</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="soru in sorular" :key="soru.id">
              <!--  <td class="p-1">
              <small>{{soru.id}}</small>
              </td>-->
              <td class="p-1">
                <small>{{ soru.text }}</small>
              </td>
              <td class="p-1">
                <div class="form-check form-check-inline">
                  <input
                      :id="soru.id"
                      v-model="cevaplar[soru.id - 1]"
                      :name="soru.id"
                      class="form-check-input"
                      required
                      type="radio"
                      value="1"
                  />
                </div>
              </td>
              <td class="p-1">
                <div class="form-check form-check-inline">
                  <input
                      :id="soru.id"
                      v-model="cevaplar[soru.id - 1]"
                      :name="soru.id"
                      class="form-check-input"
                      required
                      type="radio"
                      value="2"
                  />
                </div>
              </td>
              <td class="p-1">
                <div class="form-check form-check-inline">
                  <input
                      :id="soru.id"
                      v-model="cevaplar[soru.id - 1]"
                      :name="soru.id"
                      class="form-check-input"
                      required
                      type="radio"
                      value="3"
                  />
                </div>
              </td>
              <td class="p-1">
                <div class="form-check form-check-inline">
                  <input
                      :id="soru.id"
                      v-model="cevaplar[soru.id - 1]"
                      :name="soru.id"
                      class="form-check-input"
                      required
                      type="radio"
                      value="4"
                  />
                </div>
              </td>
              <td class="p-1">
                <div class="form-check form-check-inline">
                  <input
                      :id="soru.id"
                      v-model="cevaplar[soru.id - 1]"
                      :name="soru.id"
                      class="form-check-input"
                      required
                      type="radio"
                      value="5"
                  />
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <button class="btn btn-warning float-right mb-5" @click="submitTest">
            Tamam
          </button>
        </div>
      </form>
    </div>
    <Spinner v-if="spinner"></Spinner>
  </div>
</template>

<script>
import sorular from "../data/ontest.json";
import {gsap} from "gsap";
import {mapActions, mapState} from "vuex";
import Spinner from "@/components/Spinner";
import logger from "@/services/log.service";

export default {
  name: "OnTest",
  components: {Spinner},
  data: function () {
    return {
      spinner: false,
      sorular: "",
      cevaplar: [],
      title: "Ön Test",
    };
  },
  beforeMount() {
    if (this.section == "ontest") {
      this.sorular = sorular.OnTest;
    } else {
      this.sorular = sorular.SonTest;
      this.title = "Son Test";
    }
  },
  mounted: function () {
    this.setFooterBackground(true);
    gsap.fromTo(
        "td",
        {opacity: 0, scale: 0},
        {
          scale: 1,
          opacity: 1,
          stagger: 0.1,
          onComplete: () => {
          },
        }
    );
  },
  computed: {
    ...mapState(["section", "userId"]),
  },
  methods: {
    ...mapActions([
      "changeRoute",
      "sendOnTest",
      "setSection",
      "setFooterBackground",
    ]),
    submitTest: function () {
      this.spinner = true;

      const sonuclar = [];
      for (let i = 0; i < this.cevaplar.length; i++) {
        const cevap = this.cevaplar[i];
        sonuclar.push({
          id: i + 1,
          cevap: cevap,
        });
      }

      const isOnTest = this.section == "ontest" ? true : false;
      const model = {
        userId: this.userId,
        sonuclar: JSON.stringify(sonuclar),
        isOnTest,
      };

      this.sendOnTest(model)
          .then((response) => {
            this.spinner = false;
            if (this.section === "ontest") {
              this.setSection("ogren");
              this.changeRoute("intro");
            } else if (this.section === "sontest") {
              this.setSection("sertifika");
              this.changeRoute("sertifika");
            }
          })
          .catch((error) => {
            this.spinner = false;
            logger.log(error);
          });
    },
  },
};
</script>


<style scoped>
.on-test {
  max-width: 500px;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: #1c2d4f;
  border-radius: 0px;
}

p {
  margin: 0;
}
</style>
