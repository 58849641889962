<template>
  <section>
    <div class="share-network-list">
      <ShareNetwork
          v-for="network in networks"
          :key="network.network"
          :description="sharing.description"
          :hashtags="sharing.hashtags"
          :network="network.network"
          :quote="sharing.quote"
          :style="{ backgroundColor: network.color }"
          :title="sharing.title"
          :twitterUser="sharing.twitterUser"
          :url="sharing.url"
      >
        <i :class="network.icon"></i>
        <span>{{ network.name }}</span>
      </ShareNetwork>
    </div>
  </section>
</template>

<script>
export default {
  name: "SocialShare",
  components: {},
  data() {
    return {
      sharing: {
        url: "https://dengevedenetlemeoyunu.org/",
        title: `"Bir talebim olduğunda ne yapacağımı çok iyi biliyorum!" mu diyorsun?`,
        description: "Haydi oyna, kendini test et, arkadaşlarına meydan oku.",
        hashtags: "dengevedenetleme,dengevedenetlemeoyunu",
        twitterUser: "DengeDenetleme",
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2",
          title: `"Bir talebim olduğunda ne yapacağımı çok iyi biliyorum!" mu diyorsun?`,
          description:
              "Haydi oyna, kendini test et, arkadaşlarına meydan oku. ",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fah fa-lg fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "telegram",
          name: "Telegram",
          icon: "fab fah fa-lg fa-telegram-plane",
          color: "#0088cc",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2",
          title: `"Siyasetten çok iyi anlarım!" mı diyorsun?`,
          description:
              "Haydi oyna, kendini test et, arkadaşlarına meydan oku. ",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fah fa-lg fa-whatsapp",
          color: "#25d366",
        },
      ],
    };
  },
  computed: {},
  mounted() {
  },
  methods: {},
};
</script>


<style scoped>
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}

a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
}

a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 1 auto;
}

a[class^="share-network-"] span {
  padding: 0 10px;
  flex: 1 1 0%;
  font-weight: 500;
}
</style>
