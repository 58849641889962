<template>
  <div class="board w-100 h-100">
    <DdHeader></DdHeader>
    <div
        class="oyun-bolum d-flex justify-content-center align-items-center m-2"
    >
      <!--    <h4 class="text-center bg-yellow text-white p-2 rounded">{{title}}</h4> -->
      <img :src="image" class="img-fluid" style="max-width: 150px"/>
    </div>

    <div
        v-if="page == 'kartlar'"
        class="kartlar d-flex justify-content-center align-items-start"
    >
      <div class="row row-cols-3 align-items-center no-gutters w-100 h-100">
        <div
            v-for="soru in sorular"
            :key="soru.id"
            class="col d-flex justify-content-center align-items-center soru-item"
        >
          <OgrenCard :soru="soru" @soru-goster="soruGoster"></OgrenCard>
        </div>
      </div>
    </div>
    <soru v-if="page == 'soru'" @kapat="kapat"></soru>
  </div>
</template>

<script>
import OgrenCard from "./OgrenCard.vue";
import DdHeader from "../../DdHeader.vue";
import Soru from "./Soru.vue";
import {gsap} from "gsap";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "OgrenBoard",
  components: {
    OgrenCard,
    Soru,
    DdHeader,
  },
  data: function () {
    return {
      page: "kartlar",
      title: null,
      sorular: null,
      image: null,
    };
  },
  computed: {
    ...mapState(["currentSoruNo", "ogrenSorular", "denetleSorular", "puan"]),
    ...mapGetters(["section", "simdikiSoru", "etapTitle"]),
  },
  mounted: function () {
    this.setFooterBackground(true);
    this.title = this.etapTitle;
    if (this.section === "ogren") {
      this.sorular = this.ogrenSorular;
      this.image = "/static/OgrenRectengular.svg";
    } else {
      this.sorular = this.denetleSorular;
      this.image = "/static/DenetleRectengular.svg";
    }
    /*   gsap.from(".col", {
      scale: 0.3,
      opacity: 0,
      stagger: 0.1
    }); */
  },
  methods: {
    ...mapActions(["setCurrentSoruNo", "changeRoute", "setFooterBackground"]),
    soruGoster(soru, $event) {
      this.setCurrentSoruNo(soru);

      gsap.to(".soru-item", {
        scale: 0,
        opacity: 0,
        stagger: 0.04,
        onComplete: () => {
          this.page = "soru";
        },
      });
    },
    kapat(etapBittiMi) {
      if (etapBittiMi) {
        this.changeRoute("main-menu");
      } else {
        this.page = "kartlar";
      }
    },
  },
};
</script>


<style scoped>
.board {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff9d;
  max-width: 500px;
}

.kartlar {
  height: 75%;
}

.oyun-bolum {
  height: 11%;
}
</style>
