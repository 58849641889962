<template>
  <div class="dd-header">
    <div id="dd-logo" class="d-flex bg-darkblue">
      <div class="flex-grow-1 bd-highlight">
        <div class="">
          <img class="img-fluid" src="@/assets/dd-logo.svg"/>
        </div>
      </div>
      <div class="p-2 bd-highlight text-white" @click="toggleHelp">
        <i class="fas fa-question-circle fa-1x"></i>
      </div>
      <div
          class="p-2 bd-highlight text-white"
          @click="restartModal = !restartModal"
      >
        <i class="fas fa-power-off fa-1x"></i>
      </div>
    </div>
    <div
        id="gosterge"
        class="d-flex justify-content-center align-items-center mt-1 w-100"
    >
      <div class="d-flex justify-content-center align-items-stretch w-100">
        <div
            v-if="section != 'harekete-gec'"
            class="d-flex justify-content-between align-items-center"
        >
          <div
              v-for="koz in kozlarList"
              :id="'koz' + koz.id"
              :key="koz.id"
              class="bg-darkblue ml-1 p-1 text-white d-flex justify-content-center align-items-center rounded"
              @click="kozKullanComponent(koz)"
          >
            <i :class="koz.icon" class="fa-1x"></i>
            <span class="badge badge-light p-1 ml-1">{{ koz.kacTane }}</span>
          </div>
        </div>

        <div
            id="puan"
            class="bg-darkblue ml-1 p-1 font-text text-white d-flex justify-content-center align-items-center rounded"
        >
          Puan:
          <span class="badge badge-warning p-1 ml-1"> {{ puan }}</span>
        </div>
      </div>
    </div>
    <div v-if="showInformation" class="help">
      <Information @toggleHelp="toggleHelp"></Information>
    </div>
    <confirm-modal
        v-model="modalOpen"
        :text="kozModalMetin"
        :title="'Koz Kullan'"
        @confirm="kozKullanOnay"
    ></confirm-modal>
    <confirm-modal
        v-model="restartModal"
        :text="yenidenBaslatModalMetin"
        :title="'Yeniden Başlat'"
        @confirm="oyunuYenidenBaslat"
    ></confirm-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmModal from "@/components/modal/ConfirmModal";
import Information from "./Information";
import {gsap} from "gsap";

export default {
  name: "DdHeader",
  components: {ConfirmModal, Information},
  inject: ["notyf"],
  data: function () {
    return {
      modalOpen: false,
      aktifKoz: null,
      showInformation: false,
      kozModalMetin: "Bu kozu kullanmak istediğinize emin misiniz?",
      restartModal: false,
      yenidenBaslatModalMetin:
          "Oyunu yeniden başlatmak istediğinize emin misiniz?",
    };
  },
  computed: {
    ...mapState(["puan", "kozKullanabilir", "section"]),
    ...mapGetters(["cevaplar", "kozlar", "simdikiSoru"]),
    kozlarList() {
      if (this.section != "ogren") {
        const denetleKozlar = this.kozlar.filter((p) => p.name != "%50");
        return denetleKozlar;
      }
      return this.kozlar;
    },
    pasKoz: function () {
      return this.kozlar.find((p) => p.name == "Pas").kacTane;
    },
    yuzde: function () {
      return this.kozlar.find((p) => p.name == "%50").kacTane;
    },
    degistirKoz: function () {
      return this.kozlar.find((p) => p.name == "Değiştir").kacTane;
    },
  },
  watch: {
    puan(val) {
      gsap.fromTo(
          "#puan",
          {
            scale: 5,
            opacity: 0,
            rotate: 360,
          },
          {
            scale: 1,
            opacity: 1,
            rotate: 0,
            onComplete: () => {
            },
          }
      );
    },
    pasKoz(val) {
      var tl = gsap.timeline({repeat: 3, yoyo: true});
      tl.to("#koz1", {duration: 0.2, scale: "+=1.5", y: "+=40"});
    },
    yuzde(val) {
      var tl = gsap.timeline({repeat: 3, yoyo: true});
      tl.to("#koz2", {duration: 0.2, scale: "+=1.5", y: "+=40"});
    },
    degistirKoz(val) {
      var tl = gsap.timeline({repeat: 3, yoyo: true});
      tl.to("#koz3", {duration: 0.2, scale: "+=1.5", y: "+=40"});
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      "soruDegistir",
      "yuzdeElliKoz",
      "kozKullan",
      "showModal",
      "resetGame",
    ]),
    kozKullanComponent(koz) {
      console.log("kozKullanComponent");
      if (!this.kozKullanabilir) {
        this.notyf.error({
          message: "Koz soruda kullanılabilir.",
          className: "text-center",
          icon: false,
        });
      } else {
        if (koz.kacTane > 0) {
          console.log("koz.kacTane > 0");
          this.aktifKoz = koz;

          if (koz.name === "Pas") {
            this.kozModalMetin =
                "Bu kozu kullanırsan, sorunun doğru cevabını görürsün, puanı kazanır ve bir sonraki soruya geçersin.";
          } else if (koz.name == "%50") {
            this.kozModalMetin =
                "Bu kozu kullanırsan, yanlış cevap şıklarından 2 tanesi rastlantısal olarak silinir.";
          } else {
            this.kozModalMetin = "Bu kozu kullanırsan, yeni bir soru gelir.";
          }
          this.openModal();
        }
      }
    },
    kozKullanOnay(cevap) {
      // Modal'i kapat
      this.modalOpen = !this.modalOpen;
      const koz = this.aktifKoz;
      if (cevap === true) {
        if (koz.id === 3) {
          this.soruDegistir();
        } else if (koz.id === 2) {
          //%50
          this.yuzdeElliKoz();
        } else if (koz.id === 1 || koz.id === 4) {
          //Pas ve Ek Bilgi
          this.kozKullan(koz.id);
        }
      }
    },
    openModal() {
      this.modalOpen = !this.modalOpen;
      gsap.to(".modal-content", {
        scale: 1,
        opacity: 2,
        onComplete: () => {
        },
      });
    },
    oyunuYenidenBaslat(mi) {
      if (mi === true) this.resetGame();

      this.restartModal = false;
    },
    toggleHelp() {
      this.showInformation = !this.showInformation;
    },
  },
};
</script>


<style scoped>
.dd-header {
  cursor: pointer;
  min-height: 28px;
}

#puan {
  font-size: 0.9rem;
}

.help {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.fa-1x {
  font-size: 1.4em;
}
</style>
