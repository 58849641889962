<template>
  <div class="soru container d-flex justify-content-center align-items-start">
    <div v-if="!aciklama" class="sorunun-kendisi mt-5">
      <div
          class="text-center text-white bg-darkblue p-1 soru-metin w-100 rounded shadow"
      >
        <!--  <span class="badge badge-danger float-left">{{currentSoruNo}}</span> -->
        <img
            v-if="simdikiSoru.image"
            :src="simdikiSoru.image"
            class="img-fluid"
        />
        <div id="soru-metni" v-html="simdikiSoru.soru"></div>
      </div>

      <div class="cevaplar d-flex justify-content-center align-items-center">
        <div class="row m-0 mt-1 w-100">
          <div
              v-for="cevap in simdikiSoru.cevaplar"
              :id="cevap.id"
              :key="cevap.id"
              :class="{ invisible: cevap.hide }"
              class="col-12 bg-blue text-white cevap text-center p-0 mb-1 shadow d-flex justify-content-between align-items-center rounded"
              @click.once="cevapla(cevap.id, $event)"
          >
            <div
                class="cevap-id bg-red h-100 rounded-left d-flex align-items-center"
            >
              {{ cevap.id }}
            </div>
            <div class="text-center flex-fill cevap-metin">
              {{ cevap.text }}
              <span v-if="cevapGoster" class="badge badge-danger">
                {{ cevap.dogruCevap }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="aciklama" class="card shadow mt-5">
      <div :class="aciklamaClass" class="rounded">
        <h5 class="card-title font-title">{{ aciklamaTitle }}</h5>
        <div class="font-text" style="font-size: 12px" v-html="aciklama"></div>
        <span class="badge badge-light mb-3">{{ kazandiginKozText }}</span>
        <br/>
        <button class="btn btn-warning" @click="sonrakiSoru">Tamam</button>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Soru",
  inject: ["notyf"],
  components: {},
  data: function () {
    return {
      aciklama: null,
      aciklamaTitle: null,
      aciklamaClass: null,
      dogru: false,
      puanHakkiVar: true,
      kazandiginKozText: null,
      arkaArkaSes: new Audio(require("@/assets/audio/3dogru.mp3")),
      dogruCevapSes: new Audio(require("@/assets/audio/dogru-cevap.mp3")),
      yanlisCevapSes: new Audio(require("@/assets/audio/yanlis-cevap.mp3")),
      cevapGoster: process.env.NODE_ENV === "development" ? true : false,
    };
  },
  computed: {
    ...mapState([
      "currentSoruNo",
      "ogrenSorular",
      "denetleSorular",
      "kozKullan",
      "kozKullanilabilir",
      "arkaArkayaDogruCevap",
    ]),
    ...mapGetters(["simdikiSoru", "section", "soruSayisi"]),
  },
  created() {
    this.unwatch = this.$store.watch(
        (state, getters) => getters.kozKullan,
        (newValue, oldValue) => {
          if (newValue === "PAS") {
            this.addPuan(
                this.ogrenSorular.find((p) => p.id == this.currentSoruNo).puan
            );
            this.aciklamaClass =
                "aciklama card-body text-center text-white bg-success";
            this.aciklamaTitle = "Pas Kozunu Kullandınız.";

            this.aciklama =
                "Pas kozu ile bu sorunun kozunu ve puanını kazandınız.";
            this.dogru = true;
            gsap.from(".card", {scale: 0, opacity: 0});
          } else if (newValue === "EKBILGI") {
            console.log(newValue);
            this.aciklamaClass =
                "aciklama card-body text-center text-white bg-info";
            this.aciklamaTitle = "El Bilgi Kozunu Kullandınız.";
            this.aciklama = this.simdikiSoru.ekBilgi;
          }
        }
    );
  },
  beforeDestroy() {
    this.unwatch();
    this.updateKozKullanabilir(false);
  },
  mounted: function () {
    this.updateKozKullanabilir(true);
    gsap.from(".cevap", {
      scale: 0,
      opacity: 0,
      stagger: 0.3,
    });
  },
  methods: {
    ...mapActions([
      "addPuan",
      "addKozToUser",
      "resetKozStatus",
      "updateKozKullanabilir",
      "sonrakiSoruyaGec",
      "setArkaArkayaDogruCevap",
    ]),
    cevapla(cevapid, $event) {
      const dogruCevap = this.simdikiSoru.cevaplar.find((p) => p.dogruCevap);

      gsap.to(`#${cevapid}`, {
        x: 300,
        opacity: 0,
        onComplete: () => {
          if (cevapid == dogruCevap.id) {
            let koz = "";

            //Etap = ogren  + soruda koz tanimliysa + koz hakki varsa koz kazanabilir.
            let kozHakki =
                this.section === "ogren" &&
                this.simdikiSoru.koz &&
                this.puanHakkiVar;

            if (kozHakki) {
              this.addKozToUser(this.simdikiSoru.koz);
              this.kazandiginKozText = `Bu sorudan kazandığın koz: ${this.simdikiSoru.koz.name}`;
            }

            if (this.puanHakkiVar) this.addPuan(this.simdikiSoru.puan);

            if (this.arkaArkayaDogruCevap === 2) {
              this.arkaArkaSes.play();
              this.notyf.success({
                message: "Tebrikler! Çok iyi gidiyorsun!",
                duration: 2000,
                icon: false,
                position: {x: "center", y: "bottom"},
              });
              this.setArkaArkayaDogruCevap(0);
            } else {
              this.setArkaArkayaDogruCevap(
                  Number(this.arkaArkayaDogruCevap) + 1
              );
            }

            this.aciklamaClass =
                "aciklama card-body text-center text-white bg-success rounded";
            this.aciklamaTitle = `Doğru Cevap: \n ${dogruCevap.id}`;
            this.aciklama = `${dogruCevap.text}.`;
            if (this.section === "denetle" && this.simdikiSoru.aciklama) {
              this.aciklama += `<br/> <hr> ${this.simdikiSoru.aciklama}.`;
            }
            this.dogruCevapSes.play();
            this.dogru = true;
            this.updateKozKullanabilir(false);
            this.puanHakkiVar = true;
            return;
          }

          if (cevapid != dogruCevap.id) {
            this.yanlisCevapSes.pause();
            this.yanlisCevapSes.currentTime = 0;
            this.yanlisCevapSes.play();
            this.aciklamaClass =
                "aciklama card-body text-center text-white bg-danger";
            this.aciklamaTitle = "Yanlış cevap!";
            this.aciklama = "Öğrenmek puandan daha önemli. Hadi tekrar dene!";
            if (!this.puanHakkiVar) {
              this.aciklama = "Şimdi tekrar deneyebilirsin.";
            }

            // Koz kazanma hakkini kaybetti
            this.puanHakkiVar = false;
            this.setArkaArkayaDogruCevap(0);
            // this.notyf.error(`Yanlış Cevap. Puan Hakkınızı Kaybettiniz.`);
          }
        },
      });
    }, // Cevapla fonksiyonu bitis
    sonrakiSoru() {
      if (this.dogru) {
        gsap.to(".card", {
          scale: 0,
          opacity: 0,
          onComplete: () => {
            if (this.kozKullan) {
              this.resetKozStatus();
            }
            // Etap bitti mi?
            if (this.soruSayisi === this.simdikiSoru.id) {
              this.sonrakiSoruyaGec();
              this.$emit("kapat", true); // Etap bittiyse bu gonderiliyor
            } else {
              this.sonrakiSoruyaGec();
              this.$emit("kapat", false); // Etap bitmediyse
            }
          },
        });
      } else {
        this.aciklama = null;
      }
    },
  },
};
</script>

<style scoped>
.badge,
.cevap {
  cursor: pointer;
}

.soru-metin {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.soru {
  overflow-y: auto;
  overflow-x: hidden;
}

.cevap-id {
  min-width: 25px;
  height: 100%;
  padding: 0 8px;
  font-family: "Raleway", sans-serif;
  font-size: 13px;
}

.cevap-metin {
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  padding: 6px 0;
}

.aciklama {
}

.card-title {
}
</style>
