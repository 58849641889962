<template>
  <div class="board w-100 h-100 text-center">
    <dd-header></dd-header>
    <div
        class="oyun-bolum d-flex justify-content-center align-items-center m-2"
    >
      <img
          class="img-fluid"
          src="/static/HareketeGecRectengular.svg"
          style="max-width: 150px"
      />
    </div>
    <div
        class="harekete-gec d-flex flex-column justify-content-center align-items-center w-100"
    >
      <div
          v-if="page == 'board'"
          class="row row-cols-2 align-items-center no-gutters w-100"
      >
        <div
            v-for="item in database"
            :key="item.id"
            class="text-center col"
            style="cursor: pointer"
            @click="vakaGoster(item.Kategori)"
        >
          <div
              :class="{ 'kutu-done': gorulenVakalar.some((p) => p == item.id) }"
              class="kutu d-flex justify-content-center align-items-center font-title text-white text-center"
          >
            <span class="yazi">{{ item.title }}</span>
          </div>
        </div>
      </div>
      <HareketeGecSoru
          v-if="page == 'vaka'"
          :vaka="currentVaka"
          @goBack="goBack"
      ></HareketeGecSoru>
    </div>
    <modal v-show="modalGoster" :title="title" @kapat="modalKapat">
      <h5
          class="p-1 text-center bg-info rounded"
          style="font-family: 'Paytone One', sans-serif"
      >
        {{ title }}
      </h5>
      <div
          class="mb-1 text-center"
          style="font-family: 'Raleway', sans-serif"
          v-html="text"
      ></div>
    </modal>
    <Spinner v-if="spinner"></Spinner>
  </div>
</template>

<script>
import DdHeader from "../../DdHeader.vue";
import HareketeGecSoru from "./HareketeGecSoru";
import Spinner from "@/components/Spinner";
import {mapActions, mapGetters, mapState} from "vuex";
import Modal from "@/components/modal/Modal";
import logger from "@/services/log.service";

var lodash = require("lodash/array");
export default {
  name: "HareketeGec",
  components: {DdHeader, HareketeGecSoru, Modal, Spinner},
  data: function () {
    return {
      page: "board",
      currentVaka: null,
      gorulenVakalar: [],
      modalGoster: false,
      title: "",
      text: "",
      sonrakiModal: true,
      gosterilecekVakaSayisi: 1,
      spinner: false,
    };
  },
  mounted: function () {
    this.getHareketeGecData();

    this.title = "Harekete Geç";
    this.text =
        "Bu etapta karşına 6 başlık çıkacak. Bu başlıklardan istediğin birini seçerek bu etaba başlayabilirsin. Önce seçtiğin başlıkla ilgili bir bilgi metni göreceksin. Sonra bir örnek inceleyip, bu örneğe değerlendireceksin.";
    console.log(this.aciklamaGosterildiMi);

    var x = JSON.parse(this.aciklamaGosterildiMi);
    if (!x) {
      this.modalGoster = true;
    }
  },
  computed: {
    ...mapState([
      "level",
      "hareketeGecDb",
      "currentSoruNo",
      "aciklamaGosterildiMi",
    ]),
    ...mapGetters(["section", "simdikiSoru"]),
    database() {
      var db = lodash.uniqBy(this.$store.state.hareketeGecDb, "Kategori");
      return db;
    },
  },
  methods: {
    ...mapActions([
      "setCurrentSoruNo",
      "getHareketeGecData",
      "setAciklamaGosterildi",
      "changeRoute",
      "endGame",
    ]),
    vakaGoster(kategori) {
      const vakalar = this.hareketeGecDb.filter((p) => p.Kategori === kategori);
      console.log(vakalar);

      const random = this.randomNumber(0, vakalar.length - 1);
      console.log("RANDOM: " + random);
      this.currentVaka = vakalar[random];

      const id = this.currentVaka.id;

      console.log(`vaka:${id}`);
      if (
          this.gorulenVakalar.length < this.gosterilecekVakaSayisi &&
          !this.gorulenVakalar.some((p) => p === id)
      ) {
        this.gorulenVakalar.push(id);
        this.page = "vaka";
        this.setCurrentSoruNo(2);
      } else {
        // oyunu bitir
        /*  if (!this.gorulenVakalar.some((p) => p === id)) {
          
        } */
      }
    },
    modalKapat: function () {
      this.modalGoster = false;
      this.setAciklamaGosterildi(true);

      // Oyun bitince skor tablosuna git
      if (this.gorulenVakalar.length === this.gosterilecekVakaSayisi) {
        this.spinner = true;
        this.endGame()
            .then((response) => {
              this.spinner = false;
              this.changeRoute("score-board");
            })
            .catch((error) => {
              console.dir(error);
              this.modalTitle = "Bir hata oluştur. Tekrar dene!";
              this.modalText = error.response?.data.message;
              this.modalGoster = true;
              this.spinner = false;
              logger.log(error);
            });
      }
    },
    goBack(done) {
      if (this.gorulenVakalar.length != this.gosterilecekVakaSayisi) {
        this.page = "board";
      } else {
        // finish game
        this.title = "Tebrikler!";
        this.text =
            "Denge ve Denetleme Oyunu'nu bitirdin! Puanını görmek için hazır mısın?";
        this.modalGoster = true;
      }
    },
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>


<style scoped>
.board {
  background-color: #ffffff9d;
  max-width: 500px;
  overflow-y: auto;
}

.harekete-gec {
  height: 60%;
}

.oyun-bolum {
  height: 10%;
}

.kutu {
  min-height: 100px;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/blob-denetle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 18vh;
}

.kutu-done {
  min-height: 100px;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/blob-done.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 18vh;
}

.yazi {
  max-width: 90px;
  text-align: center;
}
</style>
