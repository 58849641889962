export const connected = state => state.connected;
export const section = state => state.section;
export const soruSayisi = state => {
    if (state.section === "ogren") {
        return state.ogrenSorular.length;
    } else if (state.section == "denetle") {
        return state.denetleSorular.length;
    }
}
export const simdikiSoru = state => {
    if (state.section === "ogren") {
        return state.ogrenSorular.filter(p => p.id == state.currentSoruNo)[0]
    } else if (state.section == "denetle") {
        return state.denetleSorular.filter(p => p.id == state.currentSoruNo)[0]
    }
}
export const kozlar = state => {
    return state.kozlar.filter(p => p.kacTane >= 0);
}
export const kozKullan = state => {
    return state.kozKullan;
}
export const kozKullanabilir = state => state.kozKullanabilir;

export const etapTitle = state => {
    let title = "";
    switch (state.section) {
        case 'ogren':
            title = "Öğren Etabı";
            break;
        case 'denetle':
            title = "Denetle Etabı";
            break;
        case 'harekete-gec':
            title = "Harekete Geç Etabı";
            break;
        default:
            break;
    }
    return title;
}