<template>
  <div class="intro d-flex justify-content-center align-items-start h-100">
    <div class="icerik w-100">
      <div class="bg-darkblue d-flex justify-content-center">
        <img class="img-fluid" src="@/assets/dd-logo.svg"/>
      </div>

      <div class="d-flex justify-content-center align-items-start">
        <img
            class="img-fluid"
            src="@/assets/oyun-logo.png"
            style="width: 25%"
        />
      </div>

      <div class="bg-blue text-white m-1">
        <div class="card-body">
          <div class="font-text" style="font-size: 10px; text-align: justify">
            <p>
              Denge ve Denetleme Oyunu 3 etaptan oluşuyor. Üç etabı da başarıyla
              bitiren kişiler, oyun sonunda isimlerine özel hazırlanmış "Dijital
              Katılım Belgesi"ne sahip olacaklar.
            </p>
            <hr/>
            <p>
              <strong>İlk Etap:</strong> Öğren! <br/>Bu etapta 9 tane soruya
              yanıt vereceksin. Sorulara ilk seferde doğru cevap verdiğinde puan
              kazanacaksın.Eğer soruya yanlış yanıt verirsen, puan
              kazanamayacaksın ancak soruyu doğru cevaplayana kadar
              ilerleyemeyeceksin. Oyundaki bazı soruları doğru yanıtladığında
              sana kolaylıklar sağlayacak olan "kozlar" kazanma şansına sahip
              olacaksın.
            </p>
            <hr/>
            <p>
              <strong>İkinci Etap:</strong> Denetle! <br/>Bu etapta da 6 tane
              soruya yanıt vereceksin. Sorulara ilk seferde doğru cevap
              verdiğinde puan kazanacaksın. Eğer ilk etapta kazandığın kozların
              hala duruyorsa (%50 kozu hariç), kozlarını istediğin soruda
              kullanabilirsin!
            </p>
            <hr/>
            <p>
              <strong>Üçüncü Etap:</strong>Harekete Geç! <br/>Bu etapta karşına
              6 başlık çıkacak. Bu başlıklardan istediğin birini seçerek etaba
              başlayabilirsin. Önce seçtiğin başlıkla ilgili bir bilgi metni
              göreceksin. Sonra bir örnek inceleyip, bu örneği
              değerlendireceksin. Yaptığın bu değerlendirme sonunda, verdiğin
              doğru yanıt kadar puan kazanacaksın.
            </p>
            <hr/>
            <p>
              Ve işte bu kadar! Artık denge ve denetleme kavramları konusunda
              bilgili ve farkındalık sahibi aktif bir vatandaş olacaksın!
            </p>
          </div>

          <div class="alert alert-danger">
            <p class="small m-0">
              Oyunun nasıl oynandığına dair detaylara istediğin her an
              ulaşabilirsin. Oyunun sağ üst köşesindeki
              <i class="fas fa-question-circle fa-1x"></i> işaretine tıklaman
              yeterli!
            </p>
          </div>

          <div class="alert alert-danger">
            <p class="small m-0">
              Oyuna istediğin her zaman yeniden başlayabilirsin. Oyunun sağ üst köşesindeki
              <i class="fas fa-power-off fa-1x"></i> işaretine tıklaman yeterli!
            </p>
          </div>

          <button
              class="m-1 btn btn-warning btn-block font-title"
              type="button"
              @click="close"
          >
            Başla
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* import { gsap } from "gsap"; */
import {mapActions} from "vuex";

export default {
  name: "Intro",
  data: function () {
    return {accordions: []};
  },
  mounted() {
    this.setFooterBackground(true);
  },
  beforeDestroy: function () {
  },
  methods: {
    ...mapActions(["changeRoute", "setFooterBackground"]),
    toggleAccordion(id) {
      this.accordions = this.accordions.map((p) => (p = false));
      this.accordions[id] = true;
    },
    close() {
      this.changeRoute("main-menu");
    },
  },
};
</script>


<style scoped>
.intro {
  background-color: #6e6e6ec7;
  max-width: 500px;
  overflow: auto;
}

hr {
  margin: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.507);
}
</style>
