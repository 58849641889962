<template>
  <div class="kvkk d-flex justify-content-center align-items-center h-100">
    <div id="icerik" class="card text-white h-100 font-text" style="font-size:10px">
      <div class="card-header">
        DENGE VE DENETLEME AĞI
        KİŞİSEL VERİLER HAKKINDA BİLGİLENDİRME VE MUVAFAKATNAME
      </div>
      <div class="card-body">
        <div class="metin">
          <p>6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) uyarınca, gerçek bir kişinin kimliğini belirli ya da
            belirlenebilir bir hale getirmeye yarayan her türlü bilgi kişisel veri kapsamındadır.</p>
          <p>Denge ve Denetleme Ağı (DDA), kişisel verilerinizin güvenliğine özen göstermektedir.</p>
          <p>DDA, KVKK gereği “Veri Sorumlusu” sıfatıyla kişisel verilerinizi KVKK’na uygun olarak ve aşağıda yer alan
            kapsamda değerlendirecektir.</p>
          <strong>Kişisel verilerin işlenme amacı</strong>
          <p>KVKK’nun 4., 5. ve 6. maddeleri uyarınca kişisel verileriniz</p>
          <ul>
            <li>Hukuka ve dürüstlük kurallarına uygun</li>
            <li>Doğru ve gerektiğinde güncel</li>
            <li>Belirli, açık ve meşru amaçlar için</li>
            <li>İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü</li>
            <li>İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme
              kurallarına uygun bir şekilde DDA’nın ve ona bağlı iktisadi işletmenin faaliyetleri ile bağlantılı olarak
              işlenecektir.
            </li>
          </ul>
          <strong>Kişisel verilerin aktarımı</strong>
          <p>
            KVKK’nun 8. ve 9. maddeleri uyarınca kişisel verileriniz yukarıda sayılan amaçlar dâhilinde, DDA’nın
            faaliyetlerinin sürdürülebilmesi için Kişisel Veri İşleme Şartları ve Amaçları çerçevesinde gerekli görülen
            üçüncü kişilere (proje/program ortağı, işbirliği yapılan kurum, tedarikçi, bağışçı, vakıf yöneticileri/
            çalışanları, hukuken yetkili kamu kurum ve kuruluşları, hukuken yetkili özel hukuk tüzel kişileri ya da yurt
            dışı ülkeler) aktarılabilecektir.
          </p>
          <strong>Kişisel verilerin toplanma yöntemi ve hukuki sebebi</strong>
          <p>Kişisel verileriniz DDA tarafından yürütülen faaliyetler başta olmak üzere etkinlik katılım ve kayıt
            formları, kurs/staj başvuru formları, iş başvuru formları, sözleşmeler, güvenlik nedeniyle alınan bilgiler
            ve kamera kayıtları gibi kanallarla ve farklı hukuki sebeplere dayalı olarak fiziki veya elektronik ortamda,
            KVKK’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında işbu
            aydınlatma metninde belirtilen amaçlarla toplanabilmekte, işlenebilmekte ve aktarılabilmektedir.</p>
          <strong>Kişisel veri sahibinin hakları</strong>
          <p>KVKK’nun 11. maddesi uyarınca kişisel veri sahibi olarak aşağıdaki haklara sahipsiniz: DDA’ya başvurarak,
            kişisel verilerinizin</p>
          <ol>
            <li>İşlenip işlenmediğini öğrenme</li>
            <li>İşlenmişse bilgi talep etme</li>
            <li>İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme</li>
            <li>Yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,</li>
            <li>Eksik / yanlış işlenmişse düzeltilmesini isteme,</li>
            <li>Kanunun 7. maddesinde öngörülen şartlar çerçevesinde silinmesini, yok edilmesini isteme,</li>
            <li>Aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini
              isteme,
            </li>
            <li>Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına
              itiraz etme,
            </li>
            <li>Kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme
              hakkına sahipsiniz.
            </li>
          </ol>
          <p>KVKK’nun 13. maddesinin 1. fıkrası gereğince, yukarıda sıralanan haklarınıza yönelik başvurularınızı
            İstanbul Politikalar Merkezi Ankara Ofisi – Güvenevler Mah. Alaçam Sok. No:1/24 Çankaya Ankara / Türkiye
            adresine yazılı olarak bildirebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en geç (30) otuz gün
            içinde başvurularınız sonuçlandırılacaktır.</p>
          <strong>Muvafakatname</strong>
          <p>6698 Sayılı Kişisel Verilerin Korunması Hakkındaki Kanun uyarınca, kişisel verilerimin DDA tarafından
            işlenmesine ilişkin yukarıdaki bilgilendirme metnini aşağıda belirttiğim tarihte temin ettiğimi; okuduğumu,
            incelediğimi, değerlendirdiğimi ve yukarıda bildirilen tüm hususları bilerek, anlayarak kabul ettiğimi;
            yukarıdaki hususların uygulanması esnasında DDAnın kusuru veya ihmali nedeniyle doğabilecek zararlarımı
            talep ve dava haklarım saklı kalmak kaydıyla, özgür iradem sonucu bu “Kişisel Veriler Hakkında Bilgilendirme
            ve Muvafakatname”de belirtilen amaç ve nedenlerle, KVKK’da tanımı yapılmış olan ve yukarıda da gerek içeriği
            gerekse toplanma yeri ve yöntemi belirtilen kişisel verilerimin yine yukarıda belirtilen kişilerle
            paylaşılmasına, KVKK’nda tanımlanan kapsamda işlenmesine muvafakat ettiğimi kabul, beyan ve taahhüt
            ederim.</p>
        </div>
      </div>
      <button class="btn btn-warning mb-1" type="button" @click="close">Kabul Ediyorum</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data: function () {
    return {};
  },
  mounted: function () {
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>


<style scoped>
.kvkk {
  overflow-y: auto;
}

.metin {
  max-height: 70vh;
  overflow-y: auto;
}

#icerik {
  background-color: #2f54abde;
  border-top: 3px dashed #f3efd4;
  border-bottom: 3px dashed #f3efd4;
  overflow-y: auto;
}

.card-header {
  padding: 0.25rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px dashed rgb(255, 255, 255);
}

.card-body {
  padding: 0.55rem;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #f4b513;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #f3efd4;
  border: 0px none #ffffff;
  border-radius: 0px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #d74242;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
