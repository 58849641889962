import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex);

const kozListesi = [
    {id: 1, name: "Pas", kacTane: 0, icon: "fas fa-forward"},
    {id: 2, name: "%50", kacTane: 0, icon: "fas fa-percent"},
    {id: 3, name: "Değiştir", kacTane: 0, icon: "fas fa-recycle"}
]

/* Oyun Akışı
0. Register ve Login
1. Ön test
2. Öğren
3. Denetle
4. Harekete Geç
5. Son test
6. Puan Tablosu
7. Sertifika
*/

const state = {
    view: 'splash-screen',
    currentSoruNo: 1,
    ogrenSorular: null,
    denetleSorular: null,
    hareketeGecDb: null,
    level: null,
    kozlar: kozListesi,
    puan: 0,
    section: "ontest",
    kozKullan: null,
    kozKullanabilir: false,
    aciklamaGosterildiMi: false,
    status: null, //loggedin status
    user: null,
    userData: null, // ontest tamamlandi mig gibi detay bilgileri tutmak icin
    session: {},
    connected: navigator.onLine,
    showFooterBackground: true,
    arkaArkayaDogruCevap: 0
};

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
});