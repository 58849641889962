<template>
  <div class="board w-100 h-100 text-center">
    <div class="bg-darkblue">
      <img class="img-fluid" src="@/assets/dd-logo.svg"/>
    </div>

    <div
        class="d-flex flex-column justify-content-start align-items-center w-100 h-100"
    >
      <img class="img-fluid" src="@/assets/oyun-logo.png" style="width: 30%"/>
      <p class="lead text-center text-white font-text" style="font-size: 14px">
        Kazandığın Puan: {{ puan }}
      </p>
      <p
          class="lead text-center font-text bg-light rounded"
          hidden
          style="font-size: 15px"
      >
        Puan Tablosu
      </p>
      <div class="d-flex justify-content-around align-items-center w-100 mb-1">
        <button
            class="m-1 btn btn-warning font-text font-kucuk"
            type="button"
            @click="liste = topTen"
        >
          En Yüksek
        </button>
        <button
            class="m-1 btn btn-warning font-text font-kucuk"
            type="button"
            @click="liste = listeWithMe"
        >
          Ben Neredeyim
        </button>
      </div>
      <ul class="list-group list-group-flush w-75 font-text">
        <li
            v-for="item in liste"
            :key="item.location"
            :class="{ 'list-group-item-danger': item.userId === userId }"
            class="list-group-item d-flex justify-content-between align-items-center"
        >
          <span class="badge badge-dark badge-pill">{{ item.location }}</span>
          {{ item.fullName }}
          <span class="badge badge-primary badge-pill">{{ item.maxPuan }}</span>
        </li>
      </ul>
      <hr/>
      <button
          class="btn text-white bg-darkblue font-text"
          type="button"
          @click="sonTestiBaslat"
      >
        Katılım belgeni almak icin son adımı tamamla
      </button>
    </div>
    <Spinner v-if="spinner"></Spinner>
  </div>
</template>

<script>
/* import { gsap } from "gsap"; */
import Spinner from "@/components/Spinner";
import {mapActions, mapState} from "vuex";
import logger from "@/services/log.service";

export default {
  name: "ScoreBoard",
  components: {Spinner},
  data: function () {
    return {
      userId: null,
      spinner: false,
      liste: [],
      topTen: [],
      listeWithMe: [],
      puanTablosuMuzik: new Audio(require("@/assets/audio/puantablosu.mp3")),
    };
  },
  computed: {
    ...mapState({
      user: "user",
      stateUserId: "userId",
      session: "session",
      puan: "puan",
      userData: "userData",
    }),
  },
  mounted() {
    this.spinner = true;
    this.userId = this.stateUserId
        ? this.stateUserId
        : localStorage.getItem("userId");
    this.getScore(this.userId)
        .then((response) => {
          this.puanTablosuMuzik.play()
          this.spinner = false;
          console.log(response);
          this.topTen = response.data.topTen;
          //console.table(this.topTen);
          this.listeWithMe = response.data.listeWithMe;
          // console.table(this.listeWithMe);
          this.liste = this.topTen;
        })
        .catch((error) => {
          this.spinner = false;
          console.dir(error);
          logger.log(error);
        });
  },
  methods: {
    ...mapActions(["getScore", "changeRoute", "setSection"]),
    sonTestiBaslat() {
      if (!this.userData?.SonTestTamamlandi) {
        this.setSection("sontest");
        this.changeRoute("on-test");
      } else {
        this.changeRoute("sertifika");
      }
    },
  },
};
</script>


<style scoped>
.board {
  background-color: #6e6e6ec7;
  max-width: 500px;
  /*   overflow: auto;
  max-height: 95%;  */
}

.list-group-item {
  padding: 0.15rem 1.25rem;
  font-size: 14px;
}

.btn {
  padding: 0.175rem 0.25rem;
  margin: 0;
}

p {
  margin-bottom: 0;
}
</style>
