<template>
  <div
      class="information d-flex justify-content-center align-items-start h-100 bg-darkblue"
  >
    <div class="icerik">
      <div class="d-flex justify-content-center align-items-start">
        <img
            class="img-fluid"
            src="@/assets/oyun-logo.png"
            style="width: 40%"
        />
      </div>
      <button
          class="btn btn-info btn-block"
          type="button"
          @click="toggleAccordion(2)"
      >
        Denge ve Denetleme Oyunu Nasıl Oynanır?
      </button>
      <section v-if="accordions[2]" class="bilgi font-text">
        <div class="card m-1">
          <div class="card-body p-1">
            <div class="font-text" style="font-size: 10px; text-align: justify">
              <p>
                Denge ve Denetleme Oyunu 3 etaptan oluşuyor. Üç etabı da
                başarıyla bitiren kişiler, oyun sonunda isimlerine özel
                hazırlanmış "Dijital Katılım Belgesi"ne sahip olacaklar.
              </p>
              <hr/>
              <p>
                <strong>İlk Etap:</strong> Öğren! <br/>Bu etapta 9 tane soruya
                yanıt vereceksin. Sorulara ilk seferde doğru cevap verdiğinde
                puan kazanacaksın.Eğer soruya yanlış yanıt verirsen, puan
                kazanamayacaksın ancak soruyu doğru cevaplayana kadar
                ilerleyemeyeceksin. Oyundaki bazı soruları doğru yanıtladığında
                sana kolaylıklar sağlayacak olan "kozlar" kazanma şansına sahip
                olacaksın. Kozların sana vereceği avantajlar:
              </p>
              <ul>
                <li>
                  <strong>Pas:</strong> Soru açıldıktan sonra bu kozu kullanmak
                  istersen, sorunun doğru cevabını görürsün, puanı kazanır ve
                  bir sonraki soruya geçersin.
                </li>
                <li>
                  <strong>%50:</strong> Bu kozu seçtiğinde, yanlış cevap
                  şıklarından 2 tanesi rastlantısal olarak silinir.
                </li>
                <li>
                  <strong>Değiştir:</strong> Soru açıldıktan sonra bu kozu
                  seçersen, yeni bir soru gelir.
                </li>
              </ul>
              <hr/>
              <p>
                <strong>İkinci Etap:</strong> Denetle! <br/>Bu etapta da 6 tane
                soruya yanıt vereceksin. Sorulara ilk seferde doğru cevap
                verdiğinde puan kazanacaksın. Eğer ilk etapta kazandığın
                kozların hala duruyorsa (%50 kozu hariç), kozlarını istediğin
                soruda kullanabilirsin!
              </p>
              <hr/>
              <p>
                <strong>Üçüncü Etap:</strong>Harekete Geç! <br/>Bu etapta
                karşına 6 başlık çıkacak. Bu başlıklardan istediğin birini
                seçerek etaba başlayabilirsin. Önce seçtiğin başlıkla ilgili bir
                bilgi metni göreceksin. Sonra bir örnek inceleyip, bu örneği
                değerlendireceksin. Yaptığın bu değerlendirme sonunda, verdiğin
                doğru yanıt kadar puan kazanacaksın.
              </p>
              <hr/>
              <p>
                Ve işte bu kadar! Artık denge ve denetleme kavramları konusunda
                bilgili ve farkındalık sahibi aktif bir vatandaş olacaksın!
              </p>
            </div>
          </div>
        </div>
      </section>

      <button
          class="btn btn-info btn-block"
          type="button"
          @click="toggleAccordion(0)"
      >
        Denge ve Denetleme Ağı Kimdir/Nedir?
      </button>
      <section v-if="accordions[0]" class="bilgi font-text">
        <div class="card m-1">
          <div class="card-body p-1">
            <p class="font-text" style="font-size: 10px; text-align: justify">
              Denge ve Denetleme Ağı (DDA), 300’e yakın sivil toplum kuruluşunu
              denge denetleme mekanizmalarıyla güçlendirilmiş, olgun, katılımcı,
              barış içinde bir arada yaşama iradesi üzerine kurulu bir demokrasi
              için mücadele etmek üzere bir araya getirmiş, Türkiye’nin en
              çoğulcu ve en geniş sivil platformu. Bu amaçla, demokratik
              vatandaşlık ve ortak yaşam bilincini geliştirmek için kamuoyu
              oluşturma ve savunuculuk faaliyetleri yürütüyoruz.
              <br/>Bu yolda araçlarımız, müzakere ve diyalog. Referans
              noktalarımızı ise evrensel ilke ve ölçütler, temel insan hak ve
              özgürlükleri ile bunları korumaya yönelik uluslararası sözleşmeler
              ve ulusal yasalar oluşturuyor. Ağımızın bu hedef doğrultusundaki
              öncelikleri, vatandaşların bilinçli karar vermelerine katkı
              sağlamak için güvenilir ve tarafsız bilgi üretmek ile aktif
              vatandaşlık bilincinin yerleşmesi için çalışmalar yürütmek.
            </p>
          </div>
        </div>
      </section>

      <button
          class="btn btn-info btn-block"
          type="button"
          @click="toggleAccordion(1)"
      >
        Neden Online Denge ve Denetleme Oyunu?
      </button>
      <section v-if="accordions[1]" class="vaka font-text">
        <div class="card m-1">
          <div class="card-body p-1">
            <p class="font-text" style="font-size: 10px; text-align: justify">
              Denge denetleme ilkeleri sadece hukuki mekanizmalarda değil,
              hayatın her alanında kullanılabilir!
              <br/>Burada meselemiz, sıradan bir güç savaşı değil. Organların
              güçlerini istismar etmemeleri, kendi sınırları içinde kalmaları,
              kendi sınırları içinde kalsalar da işlerini doğru ve hukuka uygun
              yapmaları, gücün bir elde toplanmaması için, her organ / denge
              denetleme aktörü için tanımlanmış anayasal araçlar var. Bunlara DD
              mekanizmaları diyoruz. <br/>Her ülkeye, anayasal, siyasi ve
              toplumsal düzene göre değişkenlik gösterebiliyorlar, ama amaçları
              ortak. Bu anayasal olarak tanımlanmış araçların varlığı ve
              etkinliği, yaşayabilecek tüm sorunların, çatışmaların ve
              anlaşmazlıkların, hukukla ve hukuk düzeni içinde çözülebilmesini
              sağlıyor. Aktif vatandaşlık çalışmaları kapsamında denge ve
              denetleme kavramını birlikte analiz etmek, sorgulamak, yorumlamak,
              benimsemek ve kullanmak için başladığımız bu yolculukta bizimle
              olduğun için teşekkür ederiz!
            </p>
          </div>
        </div>
      </section>

      <button
          class="btn btn-info btn-block"
          type="button"
          @click="toggleAccordion(3)"
      >
        Soru Göndermek İster misiniz?
      </button>
      <section v-if="accordions[3]" class="vaka font-text">
        <div class="card m-1">
          <div class="card-body p-1">
            <p class="font-text" style="font-size: 10px; text-align: justify">
              Denge ve Denetleme Oyunu içinde yer almasını istediğiniz soru
              önerilerinizi
              <strong>dengevedenetlemeagi@birarada.org</strong> adresine
              gönderebilirsiniz. Gönderilen sorular, bir kurul tarafından
              değerlendirildikten sonra seçilen sorulan, göndericinin ismi
              belirtilerek oyuna eklenecektir.
            </p>
          </div>
        </div>
      </section>

      <button class="m-1 btn btn-warning" type="button" @click="close">
        Geri
      </button>
    </div>
  </div>
</template>

<script>
/* import { gsap } from "gsap"; */
export default {
  name: "Information",
  data: function () {
    return {accordions: []};
  },
  beforeDestroy: function () {
  },
  methods: {
    toggleAccordion(id) {
      this.accordions = this.accordions.map((p) => (p = false));
      this.accordions[id] = true;
    },
    close() {
      this.$emit("toggleHelp");
    },
  },
};
</script>


<style scoped>
.information {
  width: 100%;
  max-width: 500px;
  z-index: 999;
  height: 100%;
  overflow-y: auto;
}

hr {
  margin: 5px 0;
}
</style>
